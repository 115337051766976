import React from 'react'
import styles from './Button.module.scss'
import clsx from 'clsx'

interface ButtonProps {
    content: {
        label: string
        icon?: boolean
    }
    className?: string
    onClick?: () => void
}

export const Button = ({ className, onClick, content }: ButtonProps) => {
    const handleClick = () => {
        onClick && onClick()
    }

    return (
        <button
            className={clsx(
                styles.wrapper,
                content.icon && styles.wrapperIcon,
                className
            )}
            onClick={handleClick}
        >
            {content.icon && (
                <img
                    className={styles.icon}
                    src="/img/layout/back-arrow-white.svg"
                    alt=""
                />
            )}
            {content.label}
        </button>
    )
}
