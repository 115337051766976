import { Dispatch, SetStateAction, useEffect } from 'react'
import { deepEqual } from 'fast-equals'
// components
import { FilterConfigProps } from '../../Filter/Filter'
import { StationObjProps } from '../../../page/Map'
// types
import { AppStateType } from '../../../../type/AppStateType'
import { PositionType } from '../../../../type/PositionType'
import { MapType } from '../../../../type/MapType'
// utils
import { fetchGolemio } from '../../../../utils/fetchGolemio'
import { sortStationsByDistanceFromPosition } from '../../../../utils/sortStationsByDistanceFromPosition'
import { filterStationsByTrashType } from '../../../../utils/filterStationsByTrashType'
import { formatBulkyWaste } from '../utils'

interface UseFetchDataProps {
    setStations: (stations: StationObjProps['stations']) => void
    setShowSpinner: Dispatch<SetStateAction<boolean>>
    setState: (state: AppStateType) => void
    position: PositionType
    filterConfig: FilterConfigProps
    map: google.maps.Map | undefined
    zoom: number
    type: MapType | undefined
}

const wasteCollectionYardsICZ = [
    'CZA00789',
    'CZA01223',
    'CZA00954',
    'CZA00005',
    'CZA00063',
    'CZA00767',
    'CZA01674',
    'CZA00017',
    'CZA00188',
    'CZA00400',
    'CZA00752',
    'CZA00230',
    'CZA00392',
    'CZA00578',
    'CZA00411',
    'CZA00547',
    'CZA00439',
    'CZA00522',
    'CZA01030',
    'CZA00955',
]

export const useFetchData = ({
    setStations,
    setState,
    setShowSpinner,
    position,
    filterConfig,
    map,
    // zoom,
    type,
}: UseFetchDataProps) => {
    let currentStates: {
        position: undefined | google.maps.LatLngLiteral
        filterConfig: FilterConfigProps
    } = {
        position: undefined,
        filterConfig: { trashTypes: [], onlyMonitored: false },
    }

    useEffect(() => {
        // check if position or filterConfig changed
        if (
            currentStates.position !== position ||
            !deepEqual(
                currentStates.filterConfig.trashTypes,
                filterConfig.trashTypes
            ) ||
            currentStates.filterConfig.onlyMonitored !==
                filterConfig.onlyMonitored
        ) {
            const positionChanged = currentStates.position !== position
            // update currentStates
            currentStates = { position, filterConfig }
            setShowSpinner(true)

            // make fetch only when position is not undefined
            if (position !== undefined && type !== undefined) {
                if (positionChanged) {
                    map?.setCenter(position)
                    // map?.setZoom(zoom)
                }
                fetchGolemio(type, `latlng=${position.lat}%2C${position.lng}`)
                    .then(async (r) => {
                        // console.log('returned response: ', r)
                        // check if a returned object has a features property and base on that set the yards state
                        if (
                            Object.prototype.hasOwnProperty.call(r, 'features')
                        ) {
                            // console.log('returned features: ', r.features)
                            // sort stations by distance from position
                            let finalStations =
                                sortStationsByDistanceFromPosition(
                                    position,
                                    r.features,
                                    type
                                )
                            if (type === 'bulky-waste') {
                                finalStations = await formatBulkyWaste(
                                    finalStations
                                )
                            }
                            if (type === 'waste-collection') {
                                finalStations = finalStations.filter(
                                    (station) => {
                                        if (!station.properties.icz) {
                                            return false
                                        }
                                        return wasteCollectionYardsICZ.includes(
                                            station.properties.icz.toUpperCase()
                                        )
                                    }
                                )
                            }
                            // filter sorted-waste stations by trash type if filter is set
                            if (filterConfig.trashTypes.length > 0) {
                                finalStations = filterStationsByTrashType(
                                    finalStations,
                                    filterConfig.trashTypes,
                                    type
                                )
                            }
                            // filter stations by monitored if filter is set
                            if (filterConfig.onlyMonitored) {
                                finalStations = finalStations.filter(
                                    (station) => station.properties.is_monitored
                                )
                            }
                            setStations(finalStations)
                        } else {
                            setStations([])
                        }
                        setState('init')
                    })
                    .catch(() => {
                        setStations([])
                        setState('error')
                    })
                    .finally(() => {
                        setShowSpinner(false)
                    })
            }
        }
    }, [position, `${filterConfig.trashTypes}`, filterConfig.onlyMonitored])
}
