import React, { Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import styles from './MapLinks.module.scss'
import { Link, useLocation } from 'react-router-dom'

interface MapLinksProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setShowSpinner: Dispatch<SetStateAction<boolean>>
    className?: string
}

export const MapLinks = ({
    className,
    setOpen,
    open,
    setShowSpinner,
}: MapLinksProps) => {
    const location = useLocation()
    const pathname = location.pathname

    const handleOnClick = () => {
        setShowSpinner(true)
    }

    return (
        <div className={clsx(styles.wrapper, className)}>
            {open && (
                <button
                    onClick={() => setOpen((prev) => !prev)}
                    className={styles.closeWrapper}
                >
                    <img
                        className={styles.closeCross}
                        src="/img/icon/cross.svg"
                        alt=""
                    />
                </button>
            )}
            <div className={styles.main}>
                <div className={styles.title}>Druh mapy</div>
                <Link
                    onClick={handleOnClick}
                    className={clsx(
                        styles.link,
                        pathname === '/' && styles.linkActives
                    )}
                    to={'/'}
                >
                    <img
                        className={styles.linkIcon}
                        src="/img/layout/trash-active.svg"
                        alt=""
                    />
                    <span className={styles.linkText}>Odpady</span>
                </Link>
                {/* re-use points */}
                <Link
                    onClick={handleOnClick}
                    className={clsx(
                        styles.link,
                        pathname === '/sberne-dvory' && styles.linkActives
                    )}
                    to={'/sberne-dvory'}
                >
                    <img
                        className={styles.linkIcon}
                        src="/img/layout/collection-active.svg"
                        alt=""
                    />
                    <span className={styles.linkText}>Sběrné dvory</span>
                </Link>
                <Link
                    onClick={handleOnClick}
                    className={clsx(
                        styles.link,
                        pathname === '/vok' && styles.linkActives
                    )}
                    to={'/vok'}
                >
                    <img
                        className={styles.linkIcon}
                        src="/img/layout/bulky-active.svg"
                        alt=""
                    />
                    <span className={styles.linkText}>
                        Velkoobjemové kontejnery
                    </span>
                </Link>
            </div>
        </div>
    )
}
