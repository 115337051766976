import { Dispatch, SetStateAction, useState } from 'react'
import { MapType } from '../../../../type/MapType'
import { SentObjProps } from '../../../../type/SentObjProps'
import { isValidEmail } from '../../../../utils/isValidEmail'
import { sendRequestsToZmenteto } from '../../../../utils/fetchZmenteto'
import { getCurrentPosition } from '../../../../utils/geolocation'
import { getDistance } from '../../../../utils/getDistance'
import { LocaleState } from '../types'
import { StationProps } from '../../../../type/StationProps'
import { ImageListType } from 'react-images-uploading'
import { wasteCollectionStates, bulkyWasteStates } from '../data'

export const useOnSubmit = (
    mapType: MapType,
    sentObj: SentObjProps | undefined,
    setLocaleStates: Dispatch<SetStateAction<LocaleState>>,
    station: StationProps | undefined,
    images: ImageListType
) => {
    // set form errors
    const [errors, setErrors] = useState<string[]>([])

    const onSubmit = async () => {
        // array with errors
        const errors = []

        // check if a container or containers are set
        if (
            mapType === 'sorted-waste' &&
            (!sentObj?.containerEval || sentObj.containerEval.length === 0)
        ) {
            errors.push('Nebyly vybrány kontejnery k hodnocení')
        }

        // check if a state is set
        if (mapType === 'sorted-waste' && !sentObj?.stateEval) {
            errors.push('Stanoviště nebylo ohodnoceno')
        }

        // check if the state requires text, if yes check, if the text is filled
        if (
            mapType === 'sorted-waste' &&
            sentObj?.stateEval &&
            sentObj.stateEval.text &&
            (!sentObj.stateText || sentObj.stateText === '')
        ) {
            errors.push(
                'V případě Jíného hodnocení stanoviště je potřeba vyplnit text'
            )
        }

        // check if an email is filled, if yes check, if it has valid format
        if (sentObj?.email && !isValidEmail(sentObj.email)) {
            errors.push('E-mail musí mít správný formát')
        }

        // check if the gdpr agreement was given
        if (!sentObj?.gpdr) {
            errors.push('Souhlas musí být poskytnut')
        }

        // set current errors
        setErrors(errors)

        // if there are any errors don't continue (error msg is provided in the UI)
        if (errors.length > 0) {
            return
        }

        // if there is no error continue with sending
        // display loading (modal is not displayed)
        setLocaleStates(() => {
            return { loading: true, modal: undefined }
        })

        // check if all required exist
        // if not show the error modal
        if (
            !station ||
            !sentObj ||
            (mapType === 'sorted-waste' && !sentObj.containerEval) ||
            (mapType === 'sorted-waste' && !sentObj.containerTrashType) ||
            (mapType === 'sorted-waste' && !sentObj.stateEval)
        ) {
            setLocaleStates(() => {
                return { loading: false, modal: 'error' }
            })
            return
        }

        // prepare vars for API request
        // station's number
        const stationNumber = String(
            station.properties.station_number ?? station.properties.id
        )
        // station's district
        const stationDistrict =
            station.properties.district ?? station.properties.cityDistrict
        // station's name
        const stationName =
            mapType === 'waste-collection' &&
            station.properties.address?.address_formatted
                ? station.properties.address.address_formatted
                : station.properties.name ?? String(station.properties.street)
        const stationDate = station.properties.date
            ? new Date(station.properties.date).toLocaleDateString('cs-CZ')
            : undefined
        // remove seconds (last :00)
        const formattedTimeFrom = station.properties.timeFrom?.slice(0, -3)
        const formattedTimeTo = station.properties.timeTo?.slice(0, -3)
        const stationTime =
            formattedTimeFrom && formattedTimeTo
                ? `${formattedTimeFrom} - ${formattedTimeTo}`
                : undefined

        // containers IDs as a string
        const evalContainersIDs = String(sentObj.containerEval?.join(', '))
        // containers trash types as a string
        const evalContainersTrashTypes = String(
            sentObj.containerTrashType?.join(', ')
        )
        // selected state's label
        const selectedState = String(sentObj.stateEval?.label)
        // text of state if it exists
        const providedText =
            sentObj.stateEval?.text && sentObj.stateText
                ? sentObj.stateText
                : ''
        const globalEval = String(
            sentObj.globalEval
                ?.map((item) => {
                    if (mapType === 'sorted-waste') {
                        return item.label
                    }
                    const states =
                        mapType === 'bulky-waste'
                            ? bulkyWasteStates
                            : wasteCollectionStates
                    // get id of item's parent from wasteCollectionStates by item's id
                    const parent = states.find((parent) =>
                        parent.options?.find((option) => option.id === item.id)
                    )
                    const parentLabel = parent?.label
                        ? `${parent.label} - `
                        : ``
                    return `${parentLabel}${item.label}`
                })
                .join('; ')
        )
        const globalEvalText = String(sentObj?.globalEvalText)

        // helper to call API requests
        const handleAPIRequests = async (
            distance: number | undefined = undefined,
            currentPosition: string | undefined = undefined
        ) => {
            try {
                const obj = {
                    images,
                    sentObj,
                    stationName,
                    stationDistrict,
                    stationNumber,
                    stationDate,
                    stationTime,
                    evalContainersIDs,
                    evalContainersTrashTypes,
                    selectedState,
                    providedText,
                    distance,
                    currentPosition,
                    mapType,
                    globalEval,
                    globalEvalText,
                }
                const response = await sendRequestsToZmenteto(obj)

                if (!response.statusCode && response.reg_num && response.id) {
                    setLocaleStates({
                        loading: false,
                        modal: 'success',
                        regNum: response.reg_num,
                    })
                } else {
                    setLocaleStates({ loading: false, modal: 'error' })
                }
            } catch (e) {
                console.log('error', e)
                setLocaleStates({ loading: false, modal: 'error' })
            }
        }

        // get current user position
        // based on that send handleAPIRequests with distance and currentPosition or without them
        getCurrentPosition({
            onSuccess: async ({
                coords: { latitude: lat, longitude: lng },
            }) => {
                // get current coordinates
                const currentPosition = { lat, lng }
                // get station's coordinates
                const stationCoordinates = station?.geometry.coordinates
                let distance: number | undefined = undefined

                // get distance between the user and the station
                if (currentPosition && stationCoordinates) {
                    distance = getDistance(currentPosition, {
                        lat: stationCoordinates[1],
                        lng: stationCoordinates[0],
                    })
                }

                // send API Requests
                await handleAPIRequests(distance, `${lat}, ${lng}`)
            },
            onError: async (e) => {
                console.log(`Error ${e.code}: ${e.message}`)

                // send API Requests
                await handleAPIRequests()
            },
        })
    }

    return { onSubmit, errors }
}
