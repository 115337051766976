const url =
    'https://obcanelimityws.praha.eu/central-api/v2/evidence/GetPrumernaNavstevnost'

export const fetchAvgOccupancy = async (id: string) => {
    try {
        // console.log('fetchAvgOccupancy', id)
        const response = await fetch(`${url}/${id}`)
        return await response.json()
    } catch (error) {
        // console.error(error)
        // fallback to CZA00789
        return {
            code: 200,
            data: [
                {
                    denVTydnu: 1,
                    hodina: 8,
                    pocet: 4.75,
                },
                {
                    denVTydnu: 1,
                    hodina: 9,
                    pocet: 5.5,
                },
                {
                    denVTydnu: 1,
                    hodina: 10,
                    pocet: 7.0,
                },
                {
                    denVTydnu: 1,
                    hodina: 11,
                    pocet: 5.75,
                },
                {
                    denVTydnu: 1,
                    hodina: 12,
                    pocet: 7.25,
                },
                {
                    denVTydnu: 1,
                    hodina: 13,
                    pocet: 3.75,
                },
                {
                    denVTydnu: 1,
                    hodina: 14,
                    pocet: 5.25,
                },
                {
                    denVTydnu: 1,
                    hodina: 15,
                    pocet: 5.25,
                },
                {
                    denVTydnu: 1,
                    hodina: 16,
                    pocet: 7.75,
                },
                {
                    denVTydnu: 2,
                    hodina: 8,
                    pocet: 2.75,
                },
                {
                    denVTydnu: 2,
                    hodina: 9,
                    pocet: 4.25,
                },
                {
                    denVTydnu: 2,
                    hodina: 10,
                    pocet: 5.25,
                },
                {
                    denVTydnu: 2,
                    hodina: 11,
                    pocet: 5.75,
                },
                {
                    denVTydnu: 2,
                    hodina: 12,
                    pocet: 5.25,
                },
                {
                    denVTydnu: 2,
                    hodina: 13,
                    pocet: 3.25,
                },
                {
                    denVTydnu: 2,
                    hodina: 14,
                    pocet: 3.75,
                },
                {
                    denVTydnu: 2,
                    hodina: 15,
                    pocet: 5.5,
                },
                {
                    denVTydnu: 2,
                    hodina: 16,
                    pocet: 4.5,
                },
                {
                    denVTydnu: 3,
                    hodina: 8,
                    pocet: 2.75,
                },
                {
                    denVTydnu: 3,
                    hodina: 9,
                    pocet: 4.75,
                },
                {
                    denVTydnu: 3,
                    hodina: 10,
                    pocet: 4.75,
                },
                {
                    denVTydnu: 3,
                    hodina: 11,
                    pocet: 6.25,
                },
                {
                    denVTydnu: 3,
                    hodina: 12,
                    pocet: 4.5,
                },
                {
                    denVTydnu: 3,
                    hodina: 13,
                    pocet: 6.25,
                },
                {
                    denVTydnu: 3,
                    hodina: 14,
                    pocet: 4.0,
                },
                {
                    denVTydnu: 3,
                    hodina: 15,
                    pocet: 5.0,
                },
                {
                    denVTydnu: 3,
                    hodina: 16,
                    pocet: 5.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 8,
                    pocet: 4.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 9,
                    pocet: 4.25,
                },
                {
                    denVTydnu: 4,
                    hodina: 10,
                    pocet: 4.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 11,
                    pocet: 5.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 12,
                    pocet: 5.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 13,
                    pocet: 4.25,
                },
                {
                    denVTydnu: 4,
                    hodina: 14,
                    pocet: 3.0,
                },
                {
                    denVTydnu: 4,
                    hodina: 15,
                    pocet: 4.75,
                },
                {
                    denVTydnu: 4,
                    hodina: 16,
                    pocet: 3.0,
                },
                {
                    denVTydnu: 5,
                    hodina: 8,
                    pocet: 3.0,
                },
                {
                    denVTydnu: 5,
                    hodina: 9,
                    pocet: 5.0,
                },
                {
                    denVTydnu: 5,
                    hodina: 10,
                    pocet: 4.5,
                },
                {
                    denVTydnu: 5,
                    hodina: 11,
                    pocet: 7.5,
                },
                {
                    denVTydnu: 5,
                    hodina: 12,
                    pocet: 7.0,
                },
                {
                    denVTydnu: 5,
                    hodina: 13,
                    pocet: 3.75,
                },
                {
                    denVTydnu: 5,
                    hodina: 14,
                    pocet: 4.5,
                },
                {
                    denVTydnu: 5,
                    hodina: 15,
                    pocet: 4.5,
                },
                {
                    denVTydnu: 5,
                    hodina: 16,
                    pocet: 5.75,
                },
                {
                    denVTydnu: 6,
                    hodina: 8,
                    pocet: 4.75,
                },
                {
                    denVTydnu: 6,
                    hodina: 9,
                    pocet: 9.5,
                },
                {
                    denVTydnu: 6,
                    hodina: 10,
                    pocet: 15.0,
                },
                {
                    denVTydnu: 6,
                    hodina: 11,
                    pocet: 14.0,
                },
                {
                    denVTydnu: 6,
                    hodina: 12,
                    pocet: 12.0,
                },
                {
                    denVTydnu: 6,
                    hodina: 13,
                    pocet: 11.75,
                },
                {
                    denVTydnu: 6,
                    hodina: 14,
                    pocet: 9.0,
                },
            ],
        }
    }
}
