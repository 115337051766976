import React from 'react'
import { ContainerProps } from '../../../type/ContainerProps'
import clsx from 'clsx'
import styles from './ContainerIcon.module.scss'
import { CircularProgressbar } from 'react-circular-progressbar'
import { TrashType } from '../../../type/TrashType'

interface ContainerIconProps {
    content: ContainerProps
    className?: string
}

export const ContainerIcon = ({ className, content }: ContainerIconProps) => {
    const percentCalculated =
        content.last_measurement && content.last_measurement.percent_calculated

    return (
        <div className={clsx(styles.wrapper, className)}>
            <img
                className={styles.large}
                src={`/img/icon/container/${
                    TrashType[content.trash_type.id]
                }.svg`}
                alt=""
            />
            {typeof percentCalculated === 'number' && (
                <div
                    className={clsx(
                        styles.progress,
                        percentCalculated >= 85 && styles.progressWarning
                    )}
                >
                    <CircularProgressbar
                        value={percentCalculated}
                        strokeWidth={10}
                    />
                </div>
            )}
        </div>
    )
}
