import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './Tab.module.scss'
import { fetchAvgOccupancy } from '../../../utils/fetchAvgOccupancy'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ChartOptions,
    LineController,
    BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

export interface TabRecordProps {
    id: number | string
    label: string
    iconName: string
    text: string
}

export interface TabProps {
    items?: TabRecordProps[]
    occupancyId?: string
    show?: boolean
    className?: string
}

ChartJS.register(
    BarController,
    BarElement,
    LineController,
    Legend,
    Tooltip,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement
)

export const detailOptions: ChartOptions = {
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'počet návštěvníků',
            },
        },
        x: {
            title: {
                display: true,
                text: 'čas (hod.)',
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: function (context: any) {
                    return `Hodina: ${context[0].label}`
                },
                label: function (context: any) {
                    return `Počet: ${context.parsed.y}`
                },
            },
        },
    },
    animation: {
        duration: 0,
    },
}

export const summaryOptions: ChartOptions = {
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'počet návštěvníků',
            },
        },
        x: {
            title: {
                display: true,
                text: 'den v týdnu',
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: function (context: any) {
                    return `Dny: ${context[0].label}`
                },
                label: function (context: any) {
                    return `Počet: ${context.parsed.y}`
                },
            },
        },
    },
    animation: {
        duration: 0,
    },
}

const chartNavTrans = {
    1: 'Po',
    2: 'Út',
    3: 'St',
    4: 'Čt',
    5: 'Pá',
    6: 'So',
    7: 'Ne',
}

type NumberedDayType = 1 | 2 | 3 | 4 | 5 | 6 | 7

export const Tab = ({
    className,
    items,
    show = false,
    occupancyId,
}: TabProps) => {
    const detailChartRef = useRef<ChartJS>(null)
    const sumChartRef = useRef<ChartJS>(null)
    const [detailData, setDetailData] = useState<any>(null)
    const [sumData, setSumData] = useState<any>(null)
    const [activeDay, setActiveDay] = useState<NumberedDayType | null>(null)

    useEffect(() => {
        if (!occupancyId) {
            return
        }
        fetchAvgOccupancy(occupancyId)
            .then((responseData) => {
                if (!responseData?.data) {
                    return
                }
                const formattedData = {
                    data: responseData.data.map((item: any) => {
                        if (item.denVTydnu === 0) {
                            item.denVTydnu = 7
                        }
                        return item
                    }),
                }
                const sortedData = {
                    data: formattedData.data.sort(
                        (a: any, b: any) => a.denVTydnu - b.denVTydnu
                    ),
                }
                const roundedData = {
                    data: sortedData.data.map((item: any) => {
                        item.pocet = Math.round(item.pocet)
                        return item
                    }),
                }
                if (roundedData.data.length === 0) {
                    return
                }
                const dayOfFirstItem = roundedData.data[0].denVTydnu
                setActiveDay(dayOfFirstItem)
                setDetailData(roundedData)

                const sumData = {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                }
                roundedData?.data.forEach((item: any) => {
                    sumData[item.denVTydnu as NumberedDayType] += item.pocet
                })
                setSumData({ data: sumData })
            })
            .catch((error) => {
                console.error(error)
            })
    }, [occupancyId])

    const dayData = detailData?.data?.filter(
        (item: any) => item.denVTydnu === activeDay
    )
    const detailDataChart = dayData
        ? {
              labels: dayData.map((item: any) => item.hodina),
              datasets: [
                  {
                      data: dayData.map((item: any) => item.pocet),
                      backgroundColor: '#528FDF',
                      borderColor: '#528FDF',
                      borderWidth: 1,
                  },
              ],
          }
        : null

    const sumDataChart = sumData
        ? {
              labels: Object.keys(sumData.data).map(
                  (item: any) => chartNavTrans[item as NumberedDayType]
              ),
              datasets: [
                  {
                      data: Object.values(sumData.data),
                      backgroundColor: '#528FDF',
                      borderColor: '#528FDF',
                      borderWidth: 1,
                  },
              ],
          }
        : null

    // get all days from the data and translate them
    const days = detailData?.data?.map((item: any) => item.denVTydnu) ?? []
    // remove duplicates, dont use new Set
    const uniqueDays = days.filter(
        (value: number, index: number, self: any) =>
            self.indexOf(value) === index
    )

    return (
        <div className={clsx(styles.wrapper, show && styles.show, className)}>
            {items &&
                items.length > 0 &&
                items.map((item) => (
                    <div key={item.id} className={styles.item}>
                        <div className={styles.header}>
                            <img
                                className={styles.icon}
                                src={`/img/icon/${item.iconName}.svg`}
                                alt=""
                            />
                        </div>
                        <div className={styles.body}>
                            <h4 className={styles.label}>{item.label}</h4>
                            <p className={styles.text}>{item.text}</p>
                        </div>
                    </div>
                ))}
            {sumDataChart && (
                <Chart
                    ref={sumChartRef}
                    type={'bar'}
                    options={summaryOptions}
                    data={sumDataChart}
                />
            )}
            {days && (
                <div className={styles.nav}>
                    {uniqueDays.map((day: NumberedDayType) => (
                        <div
                            key={day}
                            onClick={() => setActiveDay(day)}
                            className={clsx(
                                styles.navItem,
                                activeDay === day && styles.navItemActive
                            )}
                        >
                            {chartNavTrans[day]}
                        </div>
                    ))}
                </div>
            )}
            {detailDataChart && (
                <Chart
                    ref={detailChartRef}
                    type={'bar'}
                    options={detailOptions}
                    data={detailDataChart}
                />
            )}
            {detailDataChart && (
                <div className={styles.infoWrapper}>
                    <img
                        src="/img/icon/info.svg"
                        alt=""
                        className={styles.infoIcon}
                    />
                    <p className={styles.infoText}>
                        Data odpovídají průměrné návštěvnosti za poslední 3
                        měsíce.
                    </p>
                </div>
            )}
        </div>
    )
}
