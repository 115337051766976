import { Renderer } from '@googlemaps/markerclusterer'

export const renderer: Renderer = {
    render: (props: any) => {
        const { count, position, markers } = props

        const activeMarker = markers.find((marker: any) =>
            marker.getIcon().url.includes('active')
        )

        return new google.maps.Marker({
            position,
            icon: {
                url: `/img/layout/map-marker-clusterer${
                    activeMarker ? '-active' : ''
                }.svg`,
                size: new google.maps.Size(70, 70),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(35, 35),
            },
            label: {
                text: String(count),
                color: '#fff',
                fontSize: '23px',
                fontWeight: '600',
                fontFamily: 'Roboto',
            },
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        })
    },
}
