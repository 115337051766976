import React from 'react'
import { watchPosition } from './geolocation'
import { PositionType } from '../type/PositionType'

// helper wrapper for cleaner code
const handleWatcher = (
    setGeolocation: React.Dispatch<
        React.SetStateAction<google.maps.LatLngLiteral | undefined>
    >
) => {
    // set geolocation's position if enabled
    watchPosition({
        onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
            setGeolocation({ lat, lng })
        },
        onError: (e) => {
            console.log(`Error ${e.code}: ${e.message}`)
            setGeolocation(undefined)
        },
    })
}

export const handleGeocoder = (
    lat: number,
    lng: number,
    defaultLat: number,
    defaultLng: number,
    setPosition: (position: PositionType) => void,
    setGeolocation: React.Dispatch<
        React.SetStateAction<google.maps.LatLngLiteral | undefined>
    >
) => {
    const geocoder = new google.maps.Geocoder()

    geocoder
        .geocode({ location: { lat, lng } })
        .then((response) => {
            let localePosition = {
                lat: defaultLat,
                lng: defaultLng,
            }

            if (response.results[0] && response.results[0].formatted_address) {
                const address = response.results[0].formatted_address
                if (address.includes('Praha')) {
                    localePosition = { lat, lng }
                    handleWatcher(setGeolocation)
                }
            } else {
                console.log('Geocoder returned no result')
            }
            setPosition(localePosition)
        })
        .catch((e) => {
            console.log(`Geocoder caught an error: ${e}`)
            setPosition({
                lat: defaultLat,
                lng: defaultLng,
            })
        })
}
