import { StationProps } from '../type/StationProps'
import { MapType } from '../type/MapType'
import { ExtendedTrashType } from '../type/ExtendedTrashType'

export const filterStationsByTrashType = (
    stations: StationProps[],
    trashTypes: ExtendedTrashType[],
    type: MapType
) => {
    if (type === 'sorted-waste') {
        return stations.filter((station) => {
            const filteredContainers =
                station.properties.containers?.filter((container) => {
                    return trashTypes.includes(container.trash_type.id)
                }) ?? []
            return filteredContainers.length > 0
        })
    }

    if (type === 'bulky-waste') {
        return stations.filter((station) => {
            const type = station.properties.bulkyType
            return type && trashTypes.includes(type)
        })
    }
    return stations
}
