import React from 'react'
import clsx from 'clsx'
import styles from './SelectMark.module.scss'

interface SelectMarkProps {
    trigger: 'input' | 'event'
    type: 'checkbox' | 'radio'
    tag: 'span' | 'div'
    isChecked?: boolean
    className?: string
}

export const SelectMark = ({
    trigger,
    tag,
    type,
    isChecked,
    className,
}: SelectMarkProps) => {
    const Tag = tag

    return (
        <Tag
            className={clsx(
                styles.wrapper,
                styles[trigger],
                styles[type],
                isChecked && trigger === 'event' && styles.checked,
                className
            )}
        >
            {type === 'checkbox' && (
                <img
                    src={'/img/layout/form-check.svg'}
                    alt={''}
                    className={styles.symbol}
                />
            )}
        </Tag>
    )
}
