import { EvalProps } from '../../../../type/SentObjProps'

export const wasteCollectionStates: EvalProps[] = [
    {
        id: 'opening_hours',
        label: 'Otevírací doba',
        options: [
            {
                id: 'opening_hours_ok',
                label: 'Vyhovující',
            },
            {
                id: 'opening_hours_extension_workdays',
                label: 'Uvítal/a bych prodloužení otevírací doby v pracovním týdnu',
            },
            {
                id: 'opening_hours_extension_weekend',
                label: 'Uvítal/a bych prodloužení otevírací doby o víkendu',
            },
            {
                id: 'opening_hours_extension_sunday',
                label: 'Uvítal/a bych otevření sběrného dvora v neděli, na úkor jiného dne v týdnu',
            },
        ],
    },
    {
        id: 'operation',
        label: 'Provoz (čistota, orientace v areálu, možnost odložení odpadů aj.)',
        options: [
            {
                id: 'operation_cleaned',
                label: 'V areálu je vždy uklizeno',
            },
            {
                id: 'operation_not_cleaned',
                label: 'V areálu není uklizeno',
            },
            {
                id: 'operation_orientation_easy',
                label: 'Orientace v areálu je snadná',
            },
            {
                id: 'operation_orientation_difficult',
                label: 'V areálu jsem se neorientoval/a příliš dobře',
            },
            {
                id: 'operation_all_waste',
                label: 'Na tomto sběrném dvoře je možné odložit veškerý odpad, který mi vzniká',
            },
            {
                id: 'operation_not_all_waste',
                label: 'Na tomto sběrném dvoře není možné odložit veškerý odpad, který mi vzniká',
            },
        ],
    },
    {
        id: 'capacity',
        label: 'Dostatečná kapacita',
        options: [
            {
                id: 'capacity_sufficient',
                label: 'Při každé mé návštěvě je kam odložit přivezený odpad',
            },
            {
                id: 'capacity_not_sufficient_sometimes',
                label: 'Občas se stane, že odpad není kam odložit',
            },
            {
                id: 'capacity_not_sufficient_on_every_visit',
                label: 'Vždy když přijedu, jsou kontejnery na odpad plné a nemám kam odložit odpad',
            },
        ],
    },
    {
        id: 'service',
        label: 'Chování a ochota obsluhy',
        options: [
            {
                id: 'service_pleasant',
                label: 'Obsluha je příjemná a vždy nápomocná',
            },
            {
                id: 'service_not_pleasant',
                label: 'Obsluha je nepříjemná, s odložením odpadu mi nepomohla',
            },
        ],
    },
]
