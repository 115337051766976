import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { ImageListType } from 'react-images-uploading'

// types
import { LocaleState, ResultEvalProps } from './types'
import { SentObjProps } from '../../../type/SentObjProps'
import { TrashType } from '../../../type/TrashType'

// components
import { Spinner } from '../../block/Spinner/Spinner'
import { Modal } from '../../block/Modal/Modal'
import { ResultWrapper } from '../../block/ResultWrapper/ResultWrapper'
import { Button } from '../../primitive/Button/Button'
import { FormSelectable } from '../../primitive/FormSelectable/FormSelectable'
import { SelectMark } from '../../primitive/SelectMark/SelectMark'
import { CustomImgUploading } from '../CustomImgUploading/CustomImgUploading'

// utils
import { isValidEmail } from '../../../utils/isValidEmail'
import {
    allContainerChecked,
    handleAllContainer,
    handleCheckboxChange,
    handleEmailChange,
    handleSelectableContainers,
    handleSelectableStates,
    handleTextAreaChange,
} from '../../../utils/resultEvalUtils'
import { useOnSubmit } from './utils'

// data
import text from '../../../text.json'
import { wasteCollectionStates, bulkyWasteStates } from './data'

// styles
import styles from './ResultEval.module.scss'

export const ResultEval = ({
    stationEval,
    station,
    setStationEval,
    mapType,
}: ResultEvalProps) => {
    // obj with data for sending
    const [sentObj, setSentObj] = useState<SentObjProps>()
    // uploaded images
    const [images, setImages] = useState<ImageListType>([])
    // set locale states
    const [localeStates, setLocaleStates] = useState<LocaleState>({
        loading: false,
        modal: undefined,
    })
    const [openGroup, setOpenGroup] = useState<string | null>(null)
    // textarea for additional text (allowed only for selected evaluation states)
    const textRef = useRef<HTMLTextAreaElement>(null)
    // textarea for global evaluation text (allowed only for selected evaluation states)
    const globalEvalTextRef = useRef<HTMLTextAreaElement>(null)

    // console.log(station)

    // for sorted-waste check if the first step with containers is filled correctly
    const containerEvalFilled = !!(
        mapType === 'sorted-waste' &&
        sentObj?.containerEval &&
        sentObj?.containerEval.length > 0
    )

    // handle submit
    const { errors, onSubmit } = useOnSubmit(
        mapType,
        sentObj,
        setLocaleStates,
        station,
        images
    )

    return (
        <>
            <ResultWrapper
                open={stationEval === 'define-containers'}
                setOpen={() => setStationEval(null)}
                backLabel={'Zpět na detail'}
            >
                <h2 className={clsx(styles.title, 'my-3')}>
                    Čeho se podnět týká?
                </h2>
                <>
                    {mapType === 'sorted-waste' && (
                        <FormSelectable
                            content={{
                                label: 'Celé stanoviště',
                                icon: '/img/icon/container/all.svg',
                                type: 'checkbox',
                            }}
                            isChecked={allContainerChecked(station, sentObj)}
                            onClick={() => {
                                if (station) {
                                    handleAllContainer(
                                        station,
                                        sentObj,
                                        setSentObj
                                    )
                                }
                            }}
                            className={styles.selectable}
                        />
                    )}
                    {station?.properties?.containers?.map((container) => (
                        <FormSelectable
                            key={`result-eval-form-selectable-${container.container_id}`}
                            content={{
                                label: container.trash_type.description,
                                icon: `/img/icon/container/${
                                    TrashType[container.trash_type.id]
                                }.svg`,
                                type: 'checkbox',
                            }}
                            isChecked={sentObj?.containerEval?.includes(
                                container.container_id
                            )}
                            onClick={() =>
                                handleSelectableContainers(
                                    container,
                                    setSentObj
                                )
                            }
                            className={styles.selectable}
                        />
                    ))}
                    {(mapType === 'waste-collection' ||
                        mapType === 'bulky-waste') && (
                        <>
                            {(mapType === 'waste-collection'
                                ? wasteCollectionStates
                                : bulkyWasteStates
                            ).map((group) => (
                                <div
                                    className={clsx(
                                        styles.group,
                                        openGroup === group.id &&
                                            styles.groupOpen,
                                        sentObj?.globalEval?.some((curState) =>
                                            String(curState.id).startsWith(
                                                String(group.id)
                                            )
                                        ) && styles.groupFilled
                                    )}
                                    key={`result-eval-form-group-${group.id}`}
                                >
                                    <div
                                        className={styles.groupHeader}
                                        onClick={() => {
                                            // if the group is already open, close it
                                            if (openGroup === group.id) {
                                                setOpenGroup(null)
                                                return
                                            }
                                            // if the group is closed, open it
                                            setOpenGroup(String(group.id))
                                        }}
                                    >
                                        {group.label}
                                        <img
                                            src="/img/layout/accordion-arrow.svg"
                                            alt=""
                                            className={styles.groupArrow}
                                        />
                                    </div>
                                    <div className={styles.groupBody}>
                                        {group.options?.map((state) => (
                                            <FormSelectable
                                                key={`result-eval-form-group-${group.id}-form-state-${state.id}`}
                                                type={'small'}
                                                content={{
                                                    label: state.label,
                                                    type: 'radio',
                                                }}
                                                isChecked={sentObj?.globalEval?.some(
                                                    (curState) =>
                                                        curState.id === state.id
                                                )}
                                                onClick={() =>
                                                    setSentObj((prev) => {
                                                        // check if the state is already in the array
                                                        const isAlreadyIn =
                                                            prev?.globalEval?.some(
                                                                (curState) =>
                                                                    curState.id ===
                                                                    state.id
                                                            )
                                                        if (isAlreadyIn) {
                                                            return prev
                                                        }
                                                        // check if some state from the same group is already in the array
                                                        const isGroupAlreadyIn =
                                                            prev?.globalEval?.some(
                                                                (curState) =>
                                                                    String(
                                                                        curState.id
                                                                    ).startsWith(
                                                                        String(
                                                                            group.id
                                                                        )
                                                                    )
                                                            )
                                                        if (isGroupAlreadyIn) {
                                                            // remove all states from the same group
                                                            // and add the new one
                                                            return {
                                                                ...prev,
                                                                globalEval: [
                                                                    ...(
                                                                        prev?.globalEval ??
                                                                        []
                                                                    ).filter(
                                                                        (
                                                                            curState
                                                                        ) =>
                                                                            !String(
                                                                                curState.id
                                                                            ).startsWith(
                                                                                String(
                                                                                    group.id
                                                                                )
                                                                            )
                                                                    ),
                                                                    state,
                                                                ],
                                                            }
                                                        }
                                                        // add the new one
                                                        return {
                                                            ...prev,
                                                            globalEval: [
                                                                ...(prev?.globalEval ??
                                                                    []),
                                                                state,
                                                            ],
                                                        }
                                                    })
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <h2 className={clsx(styles.title, 'my-3')}>Jiné</h2>
                            <textarea
                                id={'globalEvalText'}
                                name={'globalEvalText'}
                                aria-label={'globalEvalText'}
                                className={clsx(styles.formControl, 'mt-2')}
                                ref={globalEvalTextRef}
                                placeholder={'Popište...'}
                                maxLength={255}
                                value={sentObj?.globalEvalText}
                                onChange={(e) =>
                                    handleTextAreaChange(
                                        e,
                                        setSentObj,
                                        'globalEvalText'
                                    )
                                }
                            />
                            {!sentObj?.globalEval &&
                            !sentObj?.globalEvalText ? (
                                <div className={styles.errorMsg}>
                                    Je potřeba zvolit jednu z možností nebo
                                    vyplnit text s Vaším popisem.
                                </div>
                            ) : (
                                <div className={styles.btnWrapper}>
                                    <Button
                                        onClick={() =>
                                            setStationEval('fill-form')
                                        }
                                        content={{ label: 'pokračovat' }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {containerEvalFilled && (
                        <div className={styles.btnWrapper}>
                            <Button
                                onClick={() => setStationEval('fill-form')}
                                content={{ label: 'pokračovat' }}
                            />
                        </div>
                    )}
                </>
            </ResultWrapper>
            <ResultWrapper
                open={stationEval === 'fill-form'}
                setOpen={() => setStationEval('define-containers')}
                backLabel={'Předchozí krok'}
                className={styles.fillFormWrapper}
            >
                <>
                    {mapType === 'sorted-waste' && (
                        <>
                            <h2 className={clsx(styles.title, 'my-3')}>
                                nejčastější stav stanoviště
                            </h2>
                            {text.evaluationStates.map((state) => (
                                <FormSelectable
                                    key={`evaluation-state-${state.id}`}
                                    content={{
                                        label: state.label,
                                        type: 'radio',
                                    }}
                                    className={styles.selectable}
                                    isChecked={
                                        sentObj?.stateEval?.id === state.id
                                    }
                                    onClick={() =>
                                        handleSelectableStates(
                                            state.id,
                                            setSentObj
                                        )
                                    }
                                />
                            ))}
                        </>
                    )}
                    {mapType === 'sorted-waste' && !sentObj?.stateEval && (
                        <div className={styles.errorMsg}>
                            Je potřeba zvolit stav&nbsp;stanoviště.
                        </div>
                    )}
                    {mapType === 'sorted-waste' && sentObj?.stateEval?.text && (
                        <>
                            <textarea
                                id={'evalText'}
                                name={'evalText'}
                                aria-label={'text'}
                                className={styles.formControl}
                                ref={textRef}
                                placeholder={'Popište stručně stav'}
                                maxLength={255}
                                value={sentObj.stateText}
                                onChange={(e) =>
                                    handleTextAreaChange(
                                        e,
                                        setSentObj,
                                        'stateText'
                                    )
                                }
                            />
                            {(!sentObj.stateText ||
                                sentObj.stateText.length === 0 ||
                                sentObj.stateText.length > 255) && (
                                <div className={styles.errorMsg}>
                                    Je potřeba vyplnit&nbsp;hodnocení.
                                    Max&nbsp;počet znaků je&nbsp;255.
                                </div>
                            )}
                        </>
                    )}
                    <h2 className={clsx(styles.title, 'mt-4 mb-3')}>
                        přejete si vložit fotografii?
                    </h2>
                    <CustomImgUploading
                        images={images}
                        setImages={setImages}
                        setLocaleStates={setLocaleStates}
                    />
                    <label
                        htmlFor={'email'}
                        className={clsx(styles.title, 'mt-3.5 mb-1.5')}
                    >
                        e-mail
                    </label>
                    <input
                        id={'email'}
                        name={'email'}
                        type="email"
                        className={styles.formControl}
                        placeholder={'jan.novak@seznam.cz'}
                        onChange={(e) => handleEmailChange(e, setSentObj)}
                    />
                    {sentObj &&
                        sentObj.email &&
                        !isValidEmail(sentObj.email) && (
                            <div className={styles.errorMsg}>
                                E-mailová adresa musí být zadána ve validním
                                formátu
                            </div>
                        )}
                    <input
                        id={'gdpr'}
                        name={'gdpr'}
                        type="checkbox"
                        checked={sentObj?.gpdr}
                        onChange={() => handleCheckboxChange(setSentObj)}
                        className={styles.formCheckboxInput}
                    />
                    <label
                        htmlFor="gdpr"
                        className={clsx(
                            styles.formCheckbox,
                            'mt-3.5 mb-1.5',
                            sentObj?.gpdr === false && styles.formCheckboxError
                        )}
                        data-select-mark-parent="true"
                    >
                        <SelectMark
                            type={'checkbox'}
                            trigger={'input'}
                            tag={'span'}
                            className={styles.formCheckboxMark}
                        />
                        <span className={styles.formCheckboxLabel}>
                            Souhlasím s{' '}
                            <a
                                href="/GDPR_informace_MHMP_aplikace_zmente_to_final.pdf"
                                target={'_blank'}
                            >
                                podmínkami ochrany osobních údajů
                            </a>{' '}
                            této aplikace.
                        </span>
                    </label>
                    {errors.length > 0 && (
                        <div className={styles.errorMsg}>
                            Vyplňte formulář prosím správně dle&nbsp;instrukcí,
                            aby&nbsp;jej bylo možné&nbsp;odeslat
                        </div>
                    )}
                    <div className={styles.btnWrapper}>
                        <Button
                            onClick={onSubmit}
                            content={{ label: 'Odeslat' }}
                        />
                    </div>
                    {localeStates.loading && (
                        <Spinner className={styles.spinner} />
                    )}
                </>
            </ResultWrapper>
            {localeStates.modal && (
                <Modal
                    type={localeStates.modal}
                    regNum={localeStates.regNum}
                    onClickSuccess={() => {
                        setLocaleStates({
                            loading: false,
                            modal: 'error',
                            regNum: undefined,
                        })
                        setStationEval(null)
                    }}
                    onClickError={() => {
                        setLocaleStates({ loading: false, modal: undefined })
                    }}
                />
            )}
        </>
    )
}
