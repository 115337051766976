import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './Tabs.module.scss'
import { Tab, TabProps } from '../../primitive/Tab/Tab'

export interface TabExtProps {
    id: number | string
    iconName: string
    label: string
    items?: TabProps['items']
    occupancyId?: TabProps['occupancyId']
}

export interface TabsProps {
    tabs: TabExtProps[]
    className?: string
}

export const Tabs = ({ className, tabs }: TabsProps) => {
    const [activeTab, setActiveTab] = useState<number | string | undefined>()

    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].id)
        }
    }, [])

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div className={styles.header}>
                {tabs.map((tab) => (
                    <div
                        onClick={() => setActiveTab(tab.id)}
                        key={tab.id}
                        className={clsx(
                            styles.headerItem,
                            activeTab === tab.id && styles.headerItemActive
                        )}
                    >
                        <img
                            className={styles.headerItemIcon}
                            src={`/img/icon/${tab.iconName}.svg`}
                            alt=""
                        />
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className={styles.tabs}>
                {tabs.map((tab) => (
                    <Tab
                        key={tab.id}
                        items={tab.items}
                        show={activeTab === tab.id}
                        occupancyId={tab.occupancyId}
                    />
                ))}
            </div>
        </div>
    )
}
