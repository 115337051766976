import React from 'react'
import clsx from 'clsx'
import styles from './Toggle.module.scss'

interface ToggleProps {
    active: boolean
    className?: string
}

export const Toggle = ({ active, className }: ToggleProps) => {
    return (
        <div
            className={clsx(styles.wrapper, active && styles.active, className)}
        />
    )
}
