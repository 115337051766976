import { formatPragueDistricts } from './formatPragueDistricts'

const baseUrl = window.env.REACT_APP_ZMENTETO_BASE_URL
const token = window.env.REACT_APP_ZMENTETO_BEARER

import { ImageListType } from 'react-images-uploading'
import { MapType } from '../type/MapType'

export interface submitFormProps {
    images: ImageListType
    sentObj: any
    stationName?: string
    stationDistrict?: string
    stationNumber?: string
    stationDate?: string
    stationTime?: string
    evalContainersIDs: string
    evalContainersTrashTypes: string
    selectedState: string
    providedText: string
    distance?: number
    currentPosition?: string
    mapType: MapType
    globalEval?: string
    globalEvalText?: string
}

export async function fetchZmenteto(
    body: any,
    restURL = '',
    customHeader: Headers
) {
    customHeader.set('Authorization', `Bearer ${token}`)
    const input = `${baseUrl}/survey-management${restURL}`

    const response = await fetch(input, {
        method: 'POST',
        headers: customHeader,
        body: body,
    })
    return await response.json()
}

export const sendRequestsToZmenteto = async ({
    images,
    sentObj,
    stationName,
    stationDistrict,
    stationNumber,
    stationDate,
    stationTime,
    evalContainersTrashTypes,
    selectedState,
    providedText,
    currentPosition,
    mapType,
    globalEval,
    globalEvalText,
}: submitFormProps) => {
    let imgResponse = []

    // send images to API
    if (images && images.length > 0) {
        const formData = new FormData()
        images.forEach((image) => {
            if (image.file) {
                console.log(image)
                formData.append('files', image.file)
            }
        })
        // console.log('sendImgs', formData)
        const headers = new Headers()
        imgResponse = await fetchZmenteto(formData, '/files', headers)
        // console.log('imgResponse', imgResponse)
        if (
            imgResponse.statusCode &&
            (imgResponse.statusCode < 200 || imgResponse.statusCode >= 300)
        ) {
            return imgResponse
        }
    }
    // Dobrý den, pro stanoviště tříděného odpadu číslo 0002/ 092, Blanická 552/18, oblast Praha 2, bylo zasláno hodnocení následujících kontejnerů: Multikomoditní sběr, Kovy, Barevné sklo, Čiré sklo. Hodnotící stav: Rozbitá nádoba, poskytnutý text: -.
    const sortedWasteDescription = `Dobrý den, pro stanoviště tříděného odpadu číslo ${stationNumber}, ${stationName}, oblast ${formatPragueDistricts(
        stationDistrict ?? ''
    )}, bylo zasláno hodnocení následujících kontejnerů: ${evalContainersTrashTypes}. Hodnotící stav: ${selectedState}, poskytnutý text: ${
        providedText ? providedText : '-'
    }.`
    // "Dobrý den, právě jsme obdrželi hodnocení sběrného dvoru v ulici Malešická 74, 130 00  Praha 3, které je následující: Dostatečná kapacita - Při každé mé návštěvě je kam odložit přivezený odpad; Otevírací doba - Uvítal/a bych prodloužení otevírací doby o víkendu. Poskytnutý text: Spokojenost."
    const wasteCollectionDescription = `Dobrý den, právě jsme obdrželi hodnocení sběrného dvoru v ulici ${stationName}, které je následující: ${globalEval}. Poskytnutý text: ${
        globalEvalText ? globalEvalText : '-'
    }.`
    // "Dobrý den, právě jsme obdrželi hodnocení bezplatné služby přistavení Velkoobjemného kontejneru na objemný odpad, který byl přistaven dne 28. 3. 2024, v časovém období 15:00 - 19:00, v ulici Přemyslovská x Sudoměřská, Praha 3, které je následující: Místo přistavení - Vyhovující; Dostatečná kapacita - Vždy když přijdu, je kontejner plný a nemám kam odložit odpad; Chování a ochota obsluhy - Obsluha je příjemná a vždy nápomocná. Poskytnutý text: Popisuji."
    const bulkyWasteDescription = `Dobrý den, právě jsme obdrželi hodnocení bezplatné služby přistavení Velkoobjemného kontejneru na objemný odpad, který byl přistaven dne ${stationDate}, v časovém období ${stationTime}, v ulici ${stationName}, ${formatPragueDistricts(
        stationDistrict ?? ''
    )}, které je následující: ${globalEval}. Poskytnutý text: ${
        globalEvalText ? globalEvalText : '-'
    }.`

    // body props
    const body = {
        subcategory_id: 83,
        number_of_files: imgResponse.length > 0 ? imgResponse.length : 0,
        values: {
            email: sentObj?.email || 'anonym@zmente.to',
            description:
                mapType === 'sorted-waste'
                    ? sortedWasteDescription
                    : mapType === 'waste-collection'
                    ? wasteCollectionDescription
                    : bulkyWasteDescription,
            gps: currentPosition || '50.075538, 14.4378',
            date: new Date().toISOString(),
        },
        file_ids:
            imgResponse.length > 0
                ? imgResponse.map((file: any) => file.id)
                : [],
        gdpr_agreement: sentObj?.gpdr,
        source: 'OMP',
    }

    // send evaluation (if IDs of images, if exist) to API
    const headers = new Headers()
    headers.set('accept', 'application/json')
    headers.set('Content-Type', `application/json`)
    // console.log('body', body)
    return await fetchZmenteto(
        JSON.stringify(body),
        '/clients/me/surveys',
        headers
    )
}
