import React, { Dispatch } from 'react'

export const Cookies = ({
    setShowSpinner,
}: {
    setShowSpinner: Dispatch<React.SetStateAction<boolean>>
}) => {
    setShowSpinner(false)
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh', // Full page height
                textAlign: 'center', // Center the text
            }}
        >
            <div
                style={{
                    width: '50%',
                    maxWidth: '600px',
                }}
            >
                <h1
                    style={{
                        fontSize: '50px',
                        color: 'rgb(255, 217, 102)',
                        fontWeight: 'bold',
                    }}
                >
                    Cookies
                </h1>
                <p>
                    Web{' '}
                    <a
                        href="https://odpady.mojepraha.eu/"
                        style={{ color: 'red' }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        odpady.mojepraha.eu
                    </a>{' '}
                    používá jen technická cookies, která jsou nezbytná pro
                    fungování webu. Pro webovou analytiku využíváme, který
                    cookies ke svému fungování nepotřebuje. Tento nástroj
                    negeneruje žádné trvalé identifikátory a neshromažďuje ani
                    neukládá žádné osobní nebo identifikovatelné údaje. Všechna
                    data jsou pouze agregovaná data a neobsahují žádné osobní
                    údaje. Pomocí Plausible Analytics se všechna měření webu
                    provádějí absolutně anonymně. Více informací na{' '}
                    <a
                        href="https://plausible.io/data-policy"
                        style={{ color: 'red' }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        plausible.io/data-policy
                    </a>
                    .
                </p>
            </div>
        </div>
    )
}
