import React, { Dispatch, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
// components
import { MapWrapper } from '../block/MapWrapper/MapWrapper'
import { Results } from '../block/Results/Results'
// types
import { StationProps } from '../../type/StationProps'
import { AppStateType } from '../../type/AppStateType'
import { MapType } from '../../type/MapType'

export interface MapProps {
    type: MapType
    setShowSpinner: Dispatch<React.SetStateAction<boolean>>
}

export interface ConfigProps {
    position: undefined | google.maps.LatLngLiteral
    resultListOpen: boolean
    filterOpen: boolean
    state: AppStateType
    type: MapType | undefined
}

export interface StationObjProps {
    stations: StationProps[]
    activeStation: StationProps | undefined
}

export const Map = ({ setShowSpinner, type }: MapProps) => {
    // google map instance
    const [map, setMap] = useState<google.maps.Map>()
    // global config obj
    const [config, updateConfig] = useImmer<ConfigProps>({
        // position is a current position which is used for searching
        // if geolocation is allowed and defined then position = geolocation, BUT only on init (position can change but geolocation stays)
        position: undefined,
        // result list toggler which indicates open/closed state
        resultListOpen: false,
        // filter toggler which indicates open/closed state of filter
        filterOpen: false,
        // keep app state
        state: 'idle',
        // type of map
        type: undefined,
    })

    // stations obj
    const [stationsObj, updateStationsObj] = useImmer<StationObjProps>({
        // current stations result
        stations: [],
        // active station
        activeStation: undefined,
    })

    useEffect(() => {
        updateConfig((draft) => {
            draft.type = type
        })
    }, [type])

    return (
        <>
            <MapWrapper
                setShowSpinner={setShowSpinner}
                config={config}
                updateConfig={updateConfig}
                stationsObj={stationsObj}
                updateStationsObj={updateStationsObj}
                map={map}
                setMap={setMap}
            />
            <Results
                config={config}
                updateConfig={updateConfig}
                stationsObj={stationsObj}
                updateStationsObj={updateStationsObj}
                map={map}
            />
        </>
    )
}
