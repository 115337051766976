import React from 'react'
import clsx from 'clsx'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Updater } from 'use-immer'

// components
import { ResultItem } from '../ResultItem/ResultItem'
import { ResultListToggler } from '../../primitive/ResultListToggler/ResultListToggler'
import { ConfigProps, StationObjProps } from '../../page/Map'

// types
import { StationProps } from '../../../type/StationProps'

// styles
import styles from './ResultsList.module.scss'

interface ResultsListProps {
    stations: StationProps[]
    setActiveStation: (stations: StationObjProps['activeStation']) => void
    config: ConfigProps
    updateConfig: Updater<ConfigProps>
    className?: string
}

export const ResultsList = ({
    setActiveStation,
    className,
    stations,
    config,
    updateConfig,
}: ResultsListProps) => {
    const mapType = config.type

    return (
        <div
            className={clsx(
                styles.wrapper,
                config.resultListOpen && styles.open,
                className
            )}
        >
            <ResultListToggler
                className={styles.toggler}
                config={config}
                updateConfig={updateConfig}
            />
            <div className={styles.overflowHidden}>
                <Scrollbars className={styles.overflow}>
                    {stations.length > 0 &&
                        mapType &&
                        stations
                            .filter((station) => {
                                if (mapType === 'bulky-waste') {
                                    return station.properties.street
                                }
                                return true
                            })
                            .map((station) => {
                                return (
                                    <ResultItem
                                        setActiveStation={setActiveStation}
                                        key={`result-in-list-id-${station.properties.id}-${station.properties.customId}`}
                                        content={station}
                                        mapType={mapType}
                                        className={styles.item}
                                    />
                                )
                            })}
                    {stations.length === 0 && config.state === 'init' && (
                        <div className={styles.noResults}>
                            Pro tuto oblast nebyly nalezeny žádné výsledky.
                        </div>
                    )}
                    {config.state === 'error' && (
                        <div className={styles.stateError}>
                            <p>
                                Chyba aplikace.
                                <br />
                                Prosím, aktualizujte stránku.
                            </p>
                            <a href="/">Aktualizovat</a>
                        </div>
                    )}
                    <div className={'h-24'} />
                </Scrollbars>
            </div>
        </div>
    )
}
