import React from 'react'
import styles from './Footer.module.scss'
import clsx from 'clsx'

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.logos}>
                    <a
                        href="https://www.praha.eu"
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <img
                            src="/img/layout/prague-logo.svg"
                            alt="Portál hlavního města Prahy"
                            className={clsx(styles.logo, styles.logoPrague)}
                        />
                    </a>
                    {/*<a*/}
                    {/*    href="https://operatorict.cz"*/}
                    {/*    target={'_blank'}*/}
                    {/*    rel="noreferrer"*/}
                    {/*>*/}
                    {/*    <img*/}
                    {/*        src="/img/layout/oict-logo.png"*/}
                    {/*        alt="OPERÁTOR ICT, a.s."*/}
                    {/*        className={clsx(styles.logo, styles.logoOICT)}*/}
                    {/*    />*/}
                    {/*</a>*/}
                </div>
                <div className={styles.text}>
                    &copy; Copyright {new Date().getFullYear()} - Všechna práva
                    vyhrazena.
                    <a style={{ marginLeft: '5px' }} href="/cookies">
                        - Cookies
                    </a>
                </div>
            </div>
        </footer>
    )
}
