import React from 'react'
import clsx from 'clsx'
import styles from './ResultItem.module.scss'
import { StationProps } from '../../../type/StationProps'
import { MapType } from '../../../type/MapType'
import { ContainerIcon } from '../../primitive/ContainerIcon/ContainerIcon'
import { Distance } from '../../primitive/Distance/Distance'
import { StationObjProps } from '../../page/Map'
import { getLayoutIconBasedOnMapType } from '../../../utils/getLayoutIconBasedOnMapType'
import { BulkyType } from '../../../type/BulkyType'

interface ResultItemProps {
    setActiveStation: (station: StationObjProps['activeStation']) => void
    content: StationProps
    mapType: MapType
    className?: string
}

export const ResultItem = ({
    className,
    content,
    setActiveStation,
    mapType,
}: ResultItemProps) => {
    let iconName = getLayoutIconBasedOnMapType(mapType)
    let name = content.properties.name

    if (mapType === 'waste-collection') {
        name = content.properties.address?.address_formatted ?? ''
    } else if (mapType === 'bulky-waste') {
        name = content.properties.street ?? ''
    }

    if (content.properties.is_monitored) {
        iconName = `${iconName}-smart`
    }

    if (
        mapType === 'bulky-waste' &&
        content.properties.bulkyType !== BulkyType.default
    ) {
        iconName = `${iconName}-bio`
    }

    return (
        <div
            className={clsx(styles.wrapper, className)}
            onClick={() => setActiveStation(content)}
        >
            <div className={styles.header}>
                <img
                    className={styles.trash}
                    src={`/img/layout/${iconName}.svg`}
                    alt=""
                />
            </div>
            <div className={styles.body}>
                <div className={styles.name}>{name}</div>
                <div className={styles.distance}>
                    <Distance content={{ distance: content.distance }} />
                </div>
                <div className={styles.containers}>
                    {content?.properties?.containers?.map((container) => (
                        <ContainerIcon
                            key={`result-item-${content.properties.id}-container-id-${container.container_id}`}
                            content={container}
                            className={styles.container}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
