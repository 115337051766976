import React, { useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import styles from './Search.module.scss'
import { PositionType } from '../../../type/PositionType'

const autocompleteKey = window.env.REACT_APP_GOOGLE_MAPS_API_KEY
const centerLat = Number(window.env.REACT_APP_GEOGRAPHICAL_CENTER_LAT)
const centerLng = Number(window.env.REACT_APP_GEOGRAPHICAL_CENTER_LNG)
const centerRadius = Number(window.env.REACT_APP_GEOGRAPHICAL_CENTER_RADIUS)

interface SearchProps {
    setPosition: (position: PositionType) => void
}

export const Search = ({ setPosition }: SearchProps) => {
    const center = new google.maps.LatLng(centerLat, centerLng)
    const [val, setVal] = useState('')

    // create a bounding box with sides "radius" away from the center point
    const defaultBounds = {
        north: Number(center.lat()) + centerRadius,
        south: Number(center.lat()) - centerRadius,
        east: Number(center.lng()) + centerRadius,
        west: Number(center.lng()) - centerRadius,
    }

    const { ref } = usePlacesWidget({
        apiKey: autocompleteKey,
        onPlaceSelected: (places) => {
            if (places.geometry && places.geometry.location) {
                const location = places.geometry.location
                setPosition({ lat: location.lat(), lng: location.lng() })
            }
            if (places.formatted_address) {
                setVal(places.formatted_address)
            }
        },
        options: {
            types: ['geocode'],
            componentRestrictions: { country: 'CZ' },
            bounds: defaultBounds,
            strictBounds: true,
        },
        language: 'cs',
    })

    return (
        <div className={styles.wrapper}>
            <img className={styles.search} src="/img/icon/search.svg" alt="" />
            <input
                className={styles.input}
                onChange={(e) => {
                    setVal(e.target.value)
                }}
                value={val}
                type="text"
                // @ts-ignore
                ref={ref}
                placeholder={'Zadejte polohu'}
            />
            {val.length > 0 && (
                <img
                    className={styles.closer}
                    src="/img/icon/closer.svg"
                    alt=""
                    onClick={() => setVal('')}
                />
            )}
        </div>
    )
}
