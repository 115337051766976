import { MapType } from '../type/MapType'

export const getLayoutIconBasedOnMapType = (type: MapType) => {
    switch (type) {
        case 'sorted-waste':
            return 'trash'
        case 'bulky-waste':
            return 'bulky'
        case 'waste-collection':
            return 'collection'
        default:
            return 'trash'
    }
}
