import React from 'react'
import clsx from 'clsx'
import { SelectMark } from '../SelectMark/SelectMark'
import styles from './FormSelectable.module.scss'

interface FormSelectableProps {
    content: {
        icon?: string
        label: string
        type: 'checkbox' | 'radio'
    }
    type?: 'default' | 'small'
    isChecked?: boolean
    onClick?: () => void
    className?: string
}

export const FormSelectable = ({
    content,
    isChecked,
    onClick,
    type = 'default',
    className,
}: FormSelectableProps) => {
    const handleOnClick = () => {
        onClick && onClick()
    }

    return (
        <button
            className={clsx(
                styles.wrapper,
                styles[`type-${type}`],
                styles[content.type],
                styles[type],
                isChecked && styles.checked,
                className && className
            )}
            onClick={handleOnClick}
            data-select-mark-parent={'true'}
            onMouseDown={(e) => e.preventDefault()}
        >
            <div className={styles.header}>
                {content.icon && (
                    <div className={styles.iconWrapper}>
                        <img
                            className={styles.icon}
                            src={content.icon}
                            alt=""
                        />
                    </div>
                )}
                <div className={styles.label}>{content.label}</div>
            </div>
            <SelectMark
                isChecked={isChecked}
                trigger={'event'}
                type={content.type}
                tag={'div'}
            />
        </button>
    )
}
