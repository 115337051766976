import { StationProps } from '../type/StationProps'
import { getDistance } from './getDistance'
import { MapType } from '../type/MapType'

export const sortStationsByDistanceFromPosition = (
    position: google.maps.LatLngLiteral,
    stations: StationProps[],
    type: MapType
) => {
    stations.forEach((station) => {
        const coordinates = station.geometry.coordinates

        // bulky-waste stations don't have id, so we need to use customId
        if (type === 'bulky-waste') {
            station.properties.id = station.properties.customId
        }

        station.distance = getDistance(position, {
            lat: coordinates[1],
            lng: coordinates[0],
        })
    })

    stations.sort((a, b) => (a.distance || 0) - (b.distance || 0))

    return stations
}
