import React from 'react'
import clsx from 'clsx'
import styles from './Distance.module.scss'

interface DistanceProps {
    content: {
        distance?: number
    }
    className?: string
}

export const Distance = ({ className, content }: DistanceProps) => {
    if (content.distance) {
        return (
            <div className={clsx(styles.wrapper, className)}>
                {content.distance}&nbsp;m
            </div>
        )
    } else {
        return null
    }
}
