export enum TrashType {
    tinted_glass = 1,
    electric_waste,
    metals,
    beverage_cartons,
    paper,
    plastics,
    clear_glass,
    fats,
    multi_commodity,
    // textiles,
}
