import React, { Dispatch, SetStateAction, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import clsx from 'clsx'
import { Map } from '../../organism/Map/Map'
import { Marker } from '../../primitive/Marker/Marker'
import { Search } from '../Search/Search'
import styles from './MapWrapper.module.scss'
import { MapLocation } from '../../primitive/MapLocation/MapLocation'
import { MapZoom } from '../../primitive/MapZoom/MapZoom'
import { ResultListToggler } from '../../primitive/ResultListToggler/ResultListToggler'
import { FilterConfigProps, Filter } from '../Filter/Filter'
import { useFetchData } from './hooks'
import { ConfigProps, StationObjProps } from '../../page/Map'
import { Updater } from 'use-immer'
import { AppStateType } from '../../../type/AppStateType'
import { PositionType } from '../../../type/PositionType'
import { MapLinks } from '../../organism/MapLinks/MapLinks'
import { MapLinksControls } from '../../primitive/MapLinksControls/MapLinksControls'

const key = window.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
const mapId = window.env.REACT_APP_GOOGLE_MAP_ID || ''
const defaultZoom = Number(window.env.REACT_APP_GOOGLE_MAP_DEFAULT_ZOOM) || 18
const wasteCollectionZoom =
    Number(window.env.REACT_APP_WASTE_COLLECTION_GOOGLE_MAP_DEFAULT_ZOOM) || 12

interface MapWrapperProps {
    config: ConfigProps
    updateConfig: Updater<ConfigProps>
    stationsObj: StationObjProps
    updateStationsObj: Updater<StationObjProps>
    setShowSpinner: Dispatch<SetStateAction<boolean>>
    map?: google.maps.Map
    setMap: Dispatch<SetStateAction<google.maps.Map | undefined>>
}

export const MapWrapper = ({
    config,
    updateConfig,
    setShowSpinner,
    stationsObj,
    updateStationsObj,
    map,
    setMap,
}: MapWrapperProps) => {
    // geolocation contains current user position if it is allowed
    const [geolocation, setGeolocation] = useState<PositionType>(undefined)
    // filter config
    const [filterConfig, setFilterConfig] = useState<FilterConfigProps>({
        trashTypes: [],
        onlyMonitored: false,
    })
    // handle state of map links
    const [isMapLinksOpen, setIsMapLinksOpen] = useState<boolean>(true)
    const zoom =
        config.type === 'waste-collection' ? wasteCollectionZoom : defaultZoom

    const setStations = (stations: StationObjProps['stations']) => {
        updateStationsObj((draft) => {
            draft.stations = stations
        })
    }

    // fetch data from API
    useFetchData({
        setStations,
        setShowSpinner,
        setState: (state: AppStateType) => {
            updateConfig((draft) => {
                draft.state = state
            })
        },
        position: config.position,
        filterConfig,
        map,
        zoom,
        type: config.type,
    })

    // update position helper
    const updatePosition = (position: PositionType) => {
        updateConfig((draft) => {
            draft.position = position
        })
    }

    // update active station helper
    const updateActiveStation = (station: StationObjProps['activeStation']) => {
        updateStationsObj((draft) => {
            draft.activeStation = station
        })
    }

    if (!config.type) return <></>

    return (
        <div
            className={clsx(
                styles.wrapper,
                !config.position && styles.invisible
            )}
        >
            <Wrapper apiKey={key} libraries={['places']}>
                <Map
                    disableDefaultUI={true}
                    map={map}
                    zoom={zoom}
                    position={config.position}
                    stations={stationsObj.stations}
                    mapId={mapId}
                    activeStation={stationsObj.activeStation}
                    setMap={setMap}
                    setGeolocation={setGeolocation}
                    setPosition={updatePosition}
                    setActiveStation={updateActiveStation}
                    mapType={config.type}
                >
                    {geolocation && (
                        <Marker
                            icon={{
                                // 45x45 size
                                url: `/img/layout/map-marker-user-position.svg`,
                                size: new google.maps.Size(45, 45),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(22.5, 22.5),
                            }}
                            position={geolocation}
                            zIndex={999999999999}
                        />
                    )}
                    {config.position && (
                        <Marker
                            icon={{
                                // 42x56 size
                                url: `/img/layout/map-marker-searched-address.svg`,
                                size: new google.maps.Size(42, 56),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(21, 56),
                            }}
                            position={config.position}
                            zIndex={999999999998}
                        />
                    )}
                </Map>
                <Search setPosition={updatePosition} />
                <div
                    className={clsx(
                        styles.controls,
                        (config.resultListOpen || isMapLinksOpen) &&
                            styles.controlsOpen
                    )}
                >
                    <div className={styles.controlsItem}>
                        <MapLocation
                            setPosition={updatePosition}
                            setGeolocation={setGeolocation}
                        />
                        <MapZoom map={map} />
                        <MapLinksControls
                            open={isMapLinksOpen}
                            setOpen={setIsMapLinksOpen}
                        />
                        <ResultListToggler
                            className={styles.controlList}
                            config={config}
                            updateConfig={updateConfig}
                        />
                    </div>
                </div>
                <MapLinks
                    setShowSpinner={setShowSpinner}
                    open={isMapLinksOpen}
                    setOpen={setIsMapLinksOpen}
                    className={clsx(
                        styles.controlsLinks,
                        isMapLinksOpen && styles.controlsLinksOpen
                    )}
                />
                {(config.type === 'sorted-waste' ||
                    config.type === 'bulky-waste') && (
                    <Filter
                        filterConfig={filterConfig}
                        setFilterConfig={setFilterConfig}
                        updateConfig={updateConfig}
                        config={config}
                        className={styles.filter}
                        key={`filter-${config.type}`}
                    />
                )}
            </Wrapper>
        </div>
    )
}
