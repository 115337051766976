import React from 'react'
import clsx from 'clsx'
import styles from './Spinner.module.scss'

interface SpinnerProps {
    className?: string
}

export const Spinner = ({ className }: SpinnerProps) => {
    return (
        <div className={clsx(styles.wrapper, className)}>
            <div className={styles.inner}>
                <div className={styles.icon}>
                    <img src="/img/layout/spinner.svg" alt="" />
                </div>
                <div className={styles.text}>Počkejte prosím...</div>
            </div>
        </div>
    )
}
