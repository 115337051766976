import React from 'react'
import clsx from 'clsx'
import { Scrollbars } from 'react-custom-scrollbars-2'
import styles from './ResultWrapper.module.scss'

interface ResultWrapperProps {
    open: boolean
    setOpen: (val: boolean) => void
    openAnimated?: boolean
    className?: string
    backLabel: string
    children: JSX.Element | JSX.Element[]
}

export const ResultWrapper = ({
    open,
    setOpen,
    openAnimated,
    className,
    children,
    backLabel,
}: ResultWrapperProps) => {
    return (
        <div
            className={clsx(
                styles.wrapper,
                open && styles.open,
                openAnimated && styles.openAnimated,
                className
            )}
        >
            <div className={styles.back} onClick={() => setOpen(false)}>
                <img
                    className={styles.backArrow}
                    src="/img/layout/back-arrow.svg"
                    alt=""
                />
                <div className={styles.backText}>{backLabel}</div>
            </div>
            <div className={styles.inner}>
                <Scrollbars className={styles.overflow}>
                    <div className={styles.overflowInner}>
                        {children}
                        <div className={'h-24'} />
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}
