// return distance in meters
export const getDistance = (
    mk1: google.maps.LatLngLiteral,
    mk2: google.maps.LatLngLiteral
) => {
    return Math.round(
        google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(mk1),
            new google.maps.LatLng(mk2)
        )
    )
}
