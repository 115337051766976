import React, { Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import styles from './MapLinksControls.module.scss'

export interface MapLinksControlsProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    className?: string
}

export const MapLinksControls = ({
    className,
    open,
    setOpen,
}: MapLinksControlsProps) => {
    return (
        <button
            className={clsx(styles.wrapper, open && styles.open, className)}
            onClick={() => setOpen((prev) => !prev)}
        >
            <img
                className={styles.icon}
                src="/img/icon/map-links-control.svg"
                alt=""
            />
        </button>
    )
}
