import { CustomMarkerProps } from '../type/CustomMarkerProps'
import { StationProps } from '../type/StationProps'
import { StationObjProps } from '../components/page/Map'
import { MapType } from '../type/MapType'
import { getMarkerIconBasedOnMapType } from './getMarkerIconBasedOnMapType'
import { BulkyType } from '../type/BulkyType'

// const zoom = Number(window.env.REACT_APP_GOOGLE_MAP_DEFAULT_ZOOM) || 18

// helper fn for setting marker icon
const setMarkerIcon = (
    marker: CustomMarkerProps,
    mapType: MapType,
    activeCondition = true
) => {
    const icon = marker.getIcon()
    const isMonitored = !!marker.station?.properties.is_monitored
    const isBioBulkyWaste =
        mapType === 'bulky-waste' &&
        marker.station?.properties.bulkyType !== BulkyType.default
    const isPlacedBulkyWaste = !!marker.station?.parentStation

    const iconName = getMarkerIconBasedOnMapType(
        mapType,
        activeCondition,
        isMonitored,
        isBioBulkyWaste,
        isPlacedBulkyWaste
    )

    if (typeof icon === 'object') {
        marker.setIcon({
            ...icon,
            url: `/img/layout/${iconName}.svg`,
        })
    }
}

// helper fn for setting marker on the map
const setMarker = (
    marker: CustomMarkerProps,
    map: google.maps.Map,
    mapType: MapType
) => {
    const markerPosition = marker.getPosition()

    setMarkerIcon(marker, mapType)

    if (markerPosition) {
        map.setCenter(markerPosition)
        // map.setZoom(zoom)
    }
}

// main fn
export const handleMarkers = (
    markers: CustomMarkerProps[],
    map: google.maps.Map,
    activeStation: StationProps | undefined,
    setActiveStation: (station: StationObjProps['activeStation']) => void,
    mapType: MapType
) => {
    markers.forEach((marker) => {
        const id = marker.station?.properties.id

        // if a marker is active, highlight it on the map
        if (id === activeStation?.properties.id) {
            setMarker(marker, map, mapType)
        }

        // add a click listener to all markers to handle an active station
        google.maps.event.addListener(marker, 'click', () => {
            setMarker(marker, map, mapType)

            markers.forEach((otherMarker) => {
                const id = otherMarker.station?.properties.id
                setMarkerIcon(
                    otherMarker,
                    mapType,
                    id === activeStation?.properties.id
                )
            })

            if (id) {
                setActiveStation(
                    marker.station?.parentStation ?? marker.station
                )
            }
        })
    })

    return markers
}
