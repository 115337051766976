import { EvalProps } from '../../../../type/SentObjProps'

export const bulkyWasteStates: EvalProps[] = [
    {
        id: 'place',
        label: 'Místo přistavení',
        options: [
            { id: 'place_sufficient', label: 'Vyhovující' },
            {
                id: 'place_not_found',
                label: 'Kontejner nebyl přistaven na uvedené adrese, ani v blízkém okolí',
            },
            {
                id: 'place_other_address',
                label: 'Uvítal/a bych přistavení VOKu na jiné adrese',
            },
        ],
    },
    {
        id: 'time',
        label: 'Čas přistavení',
        options: [
            { id: 'time_sufficient', label: 'Vyhovující' },
            {
                id: 'time_extension',
                label: 'Uvítal/a bych prodloužení doby přistavení kontejneru',
            },
            {
                id: 'time_more_frequent',
                label: 'Uvítala bych častější přistavení kontejneru na tomto místě',
            },
            {
                id: 'time_earlier',
                label: 'Kontejner byl odvezen dříve – tedy před koncem doby přistavení',
            },
        ],
    },
    {
        id: 'capacity',
        label: 'Dostatečná kapacita',
        options: [
            {
                id: 'capacity_sufficient',
                label: 'Vždy když přijdu je možné odpad odložit do kontejneru',
            },
            {
                id: 'capacity_full',
                label: 'Vždy když přijdu, je kontejner plný a nemám kam odložit odpad',
            },
        ],
    },
    {
        id: 'service',
        label: 'Chování a ochota obsluhy',
        options: [
            {
                id: 'service_pleasant',
                label: 'Obsluha je příjemná a vždy nápomocná',
            },
            {
                id: 'service_not_pleasant',
                label: 'Obsluha je nepříjemná, s odložením odpadu mi nepomohla',
            },
            {
                id: 'service_not_present',
                label: 'Obsluha není u kontejneru, ani v dohledné vzdálenosti',
            },
        ],
    },
]
