import React from 'react'
import clsx from 'clsx'
import styles from './ResultListToggler.module.scss'
import { ConfigProps } from '../../page/Map'
import { Updater } from 'use-immer'

interface ResultListTogglerProps {
    config: ConfigProps
    updateConfig: Updater<ConfigProps>
    className?: string
}

export const ResultListToggler = ({
    className,
    config,
    updateConfig,
}: ResultListTogglerProps) => {
    return (
        <div
            className={clsx(styles.wrapper, className)}
            onClick={() => {
                updateConfig((draft) => {
                    const newState = !draft.resultListOpen
                    if (newState) draft.filterOpen = false
                    draft.resultListOpen = newState
                })
            }}
        >
            {config.resultListOpen ? (
                <img src="/img/icon/cross.svg" alt="" />
            ) : (
                <img src="/img/icon/list.svg" alt="" />
            )}
        </div>
    )
}
