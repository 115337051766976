import React from 'react'
import clsx from 'clsx'
import text from '../../../text.json'
import { TrashType } from '../../../type/TrashType'
import { FormSelectable } from '../../primitive/FormSelectable/FormSelectable'
import styles from './Filter.module.scss'
import { Toggle } from '../../primitive/Toggle/Toggle'
import { ConfigProps } from '../../page/Map'
import { Updater } from 'use-immer'
import { BulkyType } from '../../../type/BulkyType'
import type { ExtendedTrashType } from '../../../type/ExtendedTrashType'

// extend TrashType enum to array of trash types
const ExtendedTrashType = { ...TrashType, ...BulkyType }

// get trash type object from trash type id
const trashTypeObj = (
    trashTypeID: TrashType | BulkyType,
    filterType: 'trashTypes' | 'bulkyTypes'
) => {
    const trashTypesJSON = text[filterType]
    // filter out trash type with matching id and return first element
    return trashTypesJSON.filter((type) => type.id === trashTypeID)[0]
}

export interface FilterConfigProps {
    trashTypes: ExtendedTrashType[]
    onlyMonitored: boolean
}

interface FilterProps {
    filterConfig: FilterConfigProps
    setFilterConfig: React.Dispatch<React.SetStateAction<FilterConfigProps>>
    config: ConfigProps
    updateConfig: Updater<ConfigProps>
    className?: string
}

const onlyTrash: (keyof typeof TrashType)[] = [
    'paper',
    'plastics',
    'metals',
    'beverage_cartons',
    'tinted_glass',
    'clear_glass',
    'fats',
    'electric_waste',
    'multi_commodity',
]

const onlyBulky: (keyof typeof BulkyType)[] = ['default', 'mixed']

const orderedTrashTypes: (keyof typeof ExtendedTrashType)[] = [
    ...onlyTrash,
    ...onlyBulky,
]

// generate toggle component with toggle button and toggle content
export const Filter = ({
    className,
    setFilterConfig,
    filterConfig,
    config,
    updateConfig,
}: FilterProps) => {
    const isSortedWaste = config.type === 'sorted-waste'
    const isBulkyWaste = config.type === 'bulky-waste'
    const handleSelectableClick = (trashType: ExtendedTrashType) => {
        setFilterConfig((prevState) => {
            if (prevState.trashTypes.includes(trashType)) {
                // remove trash type from filter config
                return {
                    ...prevState,
                    trashTypes: prevState.trashTypes.filter(
                        (type) => type !== trashType
                    ),
                }
            } else {
                // add trash type to filter config
                return {
                    ...prevState,
                    trashTypes: [...prevState.trashTypes, trashType],
                }
            }
        })
    }

    // toggle checking of all trash types
    // const handleSelectAllClick = () => {
    //     setFilterConfig((prevState) => {
    //         if (prevState.trashTypes.length === 0) {
    //             // add all trash types to filter config
    //             return {
    //                 ...prevState,
    //                 trashTypes: orderedTrashTypes.map(
    //                     (type) => TrashType[type]
    //                 ),
    //             }
    //         } else {
    //             // remove all trash types from filter config
    //             return {
    //                 ...prevState,
    //                 trashTypes: [],
    //             }
    //         }
    //     })
    // }

    // check if all trash types are selected
    // const isAllSelected = () => {
    //     return (
    //         orderedTrashTypes
    //             .map((type) => TrashType[type])
    //             .filter((type) => !filterConfig.trashTypes.includes(type))
    //             .length === 0
    //     )
    // }

    return (
        <div
            className={clsx(
                styles.wrapper,
                config.filterOpen && styles.open,
                className
            )}
        >
            <button
                className={styles.toggle}
                onClick={() => {
                    updateConfig((draft) => {
                        const newState = !draft.filterOpen
                        if (newState) draft.resultListOpen = false
                        draft.filterOpen = newState
                    })
                }}
            >
                <span className={styles.toggleIcon}>
                    <img
                        className={styles.toggleIconImg}
                        src={`/img/icon/filter.svg`}
                        alt=""
                    />
                </span>
                <span className={styles.toggleText}>Komodity</span>
            </button>
            <div className={styles.content}>
                {isSortedWaste && (
                    <div
                        className={styles.row}
                        onClick={() =>
                            setFilterConfig((prevState) => ({
                                ...prevState,
                                onlyMonitored: !prevState.onlyMonitored,
                            }))
                        }
                    >
                        <div className={styles.col}>Pouze měřené</div>
                        <div className={styles.col}>
                            <Toggle active={filterConfig.onlyMonitored} />
                        </div>
                    </div>
                )}
                {/*<FormSelectable*/}
                {/*    content={{*/}
                {/*        label: 'Všechny komodity',*/}
                {/*        icon: '/img/icon/container/all.svg',*/}
                {/*        type: 'checkbox',*/}
                {/*    }}*/}
                {/*    className={styles.selectable}*/}
                {/*    onClick={handleSelectAllClick}*/}
                {/*    isChecked={isAllSelected()}*/}
                {/*/>*/}
                {orderedTrashTypes
                    // filter out TrashType enum keys
                    .filter((key): key is keyof typeof ExtendedTrashType =>
                        isNaN(Number(key))
                    )
                    // if sorted waste, filter out bulky waste
                    .filter((key) => {
                        if (isSortedWaste) {
                            // check if key is in onlyTrash array
                            return onlyTrash.includes(
                                key as keyof typeof TrashType
                            )
                        }
                        if (isBulkyWaste) {
                            return onlyBulky.includes(
                                key as keyof typeof BulkyType
                            )
                        }
                    })
                    // map enum keys to FormSelectable components
                    .map((trashTypeKey: keyof typeof ExtendedTrashType) => {
                        const type = isBulkyWaste ? BulkyType : TrashType
                        const typeKey = isBulkyWaste
                            ? BulkyType[trashTypeKey as keyof typeof BulkyType]
                            : TrashType[trashTypeKey as keyof typeof TrashType]

                        return (
                            <FormSelectable
                                key={`filter-form-selectable-${trashTypeKey}`}
                                content={{
                                    label: trashTypeObj(
                                        typeKey,
                                        isBulkyWaste
                                            ? 'bulkyTypes'
                                            : 'trashTypes'
                                    ).Description,
                                    icon: `/img/icon/container/${
                                        isBulkyWaste ? '/bulky' : ''
                                    }/${type[typeKey]}.svg`,
                                    type: 'checkbox',
                                }}
                                onClick={() => {
                                    handleSelectableClick(typeKey)
                                }}
                                isChecked={filterConfig.trashTypes.includes(
                                    typeKey
                                )}
                                className={styles.selectable}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
