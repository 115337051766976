// handle FormSelectable components with selected containers all whole station
import React from 'react'
import { SentObjProps } from '../type/SentObjProps'
import text from '../text.json'
import { StationProps } from '../type/StationProps'
import { ContainerProps } from '../type/ContainerProps'

// handle containers data used by Selectable components
export const handleSelectableContainers = (
    container: ContainerProps,
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>
) => {
    const curId = container.container_id
    const curTrashType = container.trash_type.description

    setSentObj((prevState) => {
        const oldContainerEval = prevState?.containerEval
        const oldContainerTrashType = prevState?.containerTrashType
        let newContainerEval = oldContainerEval ? oldContainerEval : []
        let newContainerTrashType = oldContainerTrashType
            ? oldContainerTrashType
            : []

        // if container is added or removed
        if (oldContainerEval?.includes(curId)) {
            newContainerEval = newContainerEval.filter((item) => item !== curId)
            newContainerTrashType = newContainerTrashType.filter(
                (item) => item !== curTrashType
            )
        } else {
            newContainerEval = [...newContainerEval, curId]
            newContainerTrashType = [...newContainerTrashType, curTrashType]
        }

        // update whole state
        return {
            ...prevState,
            containerEval: newContainerEval,
            containerTrashType: newContainerTrashType,
        }
    })
}

// handle states (of containers during evaluation) used by Selectable components
export const handleSelectableStates = (
    curId: number,
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>
) => {
    setSentObj((prevState) => {
        const curItem = text.evaluationStates.filter(
            (state) => state.id === curId
        )[0]
        return {
            ...prevState,
            stateEval: curItem,
        }
    })
}

// get IDs of all containers of current station
export const getAllContainersIDs = (station: StationProps) =>
    station?.properties?.containers?.map((container) => container.container_id)

// get trash types of all containers of current station
export const getAllContainersTrashTypes = (station: StationProps) =>
    station?.properties?.containers?.map(
        (container) => container.trash_type.description
    )

// return boolean if all containers are checked or not
export const allContainerChecked = (
    station: StationProps | undefined,
    sentObj: SentObjProps | undefined
) => {
    const count = station?.properties?.containers?.length
    const curCount = sentObj?.containerEval?.length

    // be sure that count exists, and it is higher than 0
    return !!(count && count > 0 && count === curCount)
}

// toggle between 0 or all containers
export const handleAllContainer = (
    station: StationProps,
    sentObj: SentObjProps | undefined,
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>
) => {
    setSentObj((prevState) => {
        if (allContainerChecked(station, sentObj)) {
            return { ...prevState, containerEval: [], containerTrashType: [] }
        } else {
            return {
                ...prevState,
                containerEval: getAllContainersIDs(station),
                containerTrashType: getAllContainersTrashTypes(station),
            }
        }
    })
}

// update gdpr checkbox
export const handleCheckboxChange = (
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>
) => {
    setSentObj((prevState) => {
        return { ...prevState, gpdr: !prevState?.gpdr }
    })
}

// update state textarea
export const handleTextAreaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>,
    property: 'globalEvalText' | 'stateText'
) => {
    setSentObj((prevState) => {
        return { ...prevState, [property]: e.target.value }
    })
}

// update email input
export const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setSentObj: React.Dispatch<React.SetStateAction<SentObjProps | undefined>>
) => {
    setSentObj((prevState) => {
        return { ...prevState, email: e.target.value }
    })
}
