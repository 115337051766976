import { StationProps } from '../../../../type/StationProps'
import { BulkyType } from '../../../../type/BulkyType'
import { fetchVenzeo } from '../../../../utils/fetchVenzeo'
// import { getDistance } from '../../../../utils/getDistance'

const defaultServiceName = 'VOK'

// received bulky stations from the API are not grouped by coordinates
// API returns multiple records for the same station, but with different times (properties.date/timeFrom/timeTo)
// because of that we need to group stations with the same coordinates under one object (the first station in the array)
// all bulky waste stations are put under the properties.bulky_waste array, including the first station
export const formatBulkyWaste = async (stations: StationProps[]) => {
    const formattedStations: StationProps[] = []

    stations.forEach((station) => {
        const coordinates = station.geometry.coordinates
        const lon = parseFloat(coordinates[0].toFixed(6))
        const lat = parseFloat(coordinates[1].toFixed(6))
        const key = `geometry-coordinates-${lat}-${lon}`

        // check if the station already exists
        const existingStation = formattedStations.find(
            (station) => station.key === key
        )

        // if exists, add the container to the existing station
        // if not, create a new station
        if (existingStation) {
            // if the station already exists, add the container to the existing station
            existingStation.properties.bulky_waste?.push(station)
        } else {
            // if the station doesn't exist, create a new station
            const newStation = {
                ...station,
                geometry: {
                    coordinates: [lon, lat] as [number, number],
                },
                key,
                properties: {
                    ...station.properties,
                    bulky_waste: [station],
                },
            }
            formattedStations.push(newStation)
        }
    })

    const venzeoResponse: any[] = await fetchVenzeo()

    formattedStations.forEach((station) => {
        const bulkyWasteStations = station.properties.bulky_waste ?? []
        const isMixed = bulkyWasteStations.some(
            (bulkyWasteStation) =>
                bulkyWasteStation.properties.serviceName !== defaultServiceName
        )
        station.properties.bulkyType = isMixed
            ? BulkyType.mixed
            : BulkyType.default

        venzeoResponse.forEach((venzeoStation) => {
            // const distance = getDistance(
            //     {
            //         lat: Number(venzeoStation.location.lat),
            //         lng: Number(venzeoStation.location.lon),
            //     },
            //     {
            //         lat: station.geometry.coordinates[1],
            //         lng: station.geometry.coordinates[0],
            //     }
            // )
            // if (distance <= venzeoStation.location.accuracy_in_meters) {
            station.properties.bulky_waste?.forEach((bulkyWasteStation) => {
                if (
                    bulkyWasteStation.properties.customId ===
                    venzeoStation.custom_id
                ) {
                    station.activeStation = {
                        key: `venzeo-geometry-coordinates-${venzeoStation.location.lat}-${venzeoStation.location.lon}`,
                        geometry: {
                            coordinates: [
                                Number(venzeoStation.location.lon),
                                Number(venzeoStation.location.lat),
                            ],
                        },
                        properties: {
                            id: station.properties.id,
                            customId: station.properties.customId,
                            bulkyType: station.properties.bulkyType,
                        },
                    }
                    formattedStations.push({
                        ...station.activeStation,
                        parentStation: station,
                    })
                    // venzeoStations.push(station.activeStation)
                }
            })
            // }
        })
    })

    // console.log('formattedStations', formattedStations)

    return formattedStations
}
