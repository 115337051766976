import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import clsx from 'clsx'
import styles from './Modal.module.scss'
import { Button } from '../../primitive/Button/Button'

interface ModalProps {
    type: 'success' | 'error'
    regNum?: number
    className?: string
    onClickSuccess: () => void
    onClickError: () => void
}

const text = {
    success: {
        title: 'Děkujeme za Váš podnět',
        button: 'pokračovat',
    },
    error: {
        title: 'Odeslání se nezdařilo',
        button: 'zpět',
    },
}

export const Modal = ({
    type,
    regNum,
    className,
    onClickSuccess,
    onClickError,
}: ModalProps) => {
    return (
        <div className={clsx(styles.wrapper, styles[type], className)}>
            <div className={styles.body}>
                <Scrollbars className={styles.overflow}>
                    <div className={styles.content}>
                        <img
                            className={styles.icon}
                            src={`/img/icon/${type}.svg`}
                            alt=""
                        />
                        <h2 className={styles.title}>{text[type].title}</h2>
                        <div className={styles.text}>
                            {type === 'success' ? (
                                <>
                                    Váš podnět s&nbsp;registračním číslem{' '}
                                    {regNum} byl&nbsp;odeslán do&nbsp;městské
                                    aplikace&nbsp;Změňte.to.
                                    Nyní&nbsp;se&nbsp;mu budeme
                                    náležitě&nbsp;věnovat.
                                </>
                            ) : (
                                <>
                                    Odeslání se nezdařilo. Zkontrolujte prosím
                                    vyplněné informace nebo&nbsp;zkuste
                                    aktualizovat&nbsp;stránku.
                                </>
                            )}
                        </div>
                        <Button
                            content={{
                                label: text[type].button,
                                icon: type === 'error',
                            }}
                            onClick={() => {
                                if (type === 'error') {
                                    onClickError()
                                } else {
                                    onClickSuccess()
                                }
                            }}
                        />
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}
