import React from 'react'
import clsx from 'clsx'
import styles from './MapLocation.module.scss'
import { getCurrentPosition } from '../../../utils/geolocation'
import { handleGeocoder } from '../../../utils/handleGeocoder'
import { PositionType } from '../../../type/PositionType'

// lat and lng for default position
const defaultLat = Number(window.env.REACT_APP_DEFAULT_CENTER_LAT || 0)
const defaultLng = Number(window.env.REACT_APP_DEFAULT_CENTER_LNG || 0)

interface MapLocationProps {
    setGeolocation: React.Dispatch<
        React.SetStateAction<undefined | google.maps.LatLngLiteral>
    >
    setPosition: (position: PositionType) => void
    className?: string
}

export const MapLocation = ({
    className,
    setGeolocation,
    setPosition,
}: MapLocationProps) => {
    const handleClick = () => {
        // set the position state (fill it by geolocation's coordinates or by default values from .env)
        getCurrentPosition({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                // check with geocoder that user is in Praha (geolocation is enabled only in Praha)
                handleGeocoder(
                    lat,
                    lng,
                    defaultLat,
                    defaultLng,
                    setPosition,
                    setGeolocation
                )
            },
            onError: (e) => {
                console.log(`Error ${e.code}: ${e.message}`)
                window.alert(
                    e.code === 1
                        ? 'Přístup k poloze není povolen. Pro zobrazení Vaší lokace povolte sdílení a aktualizujte stránku.'
                        : 'Vaši polohu se nepodařilo získat.'
                )
                setPosition({ lat: defaultLat, lng: defaultLng })
            },
        })
    }

    return (
        <div onClick={handleClick} className={clsx(styles.wrapper, className)}>
            <img src="/img/layout/map-control-location.svg" alt="" />
        </div>
    )
}
