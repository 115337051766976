import React, { SetStateAction } from 'react'
import ImageUploading, {
    ImageListType,
    ImageType,
} from 'react-images-uploading'
import imageCompression from 'browser-image-compression'
import styles from './CustomImgUploading.module.scss'

interface CustomImgUploadingProps {
    images: ImageListType
    setImages: React.Dispatch<SetStateAction<ImageListType>>
    setLocaleStates: React.Dispatch<SetStateAction<any>>
}

export const CustomImgUploading = ({
    images,
    setImages,
    setLocaleStates,
}: CustomImgUploadingProps) => {
    // max num of files
    const maxNum = 3
    // allowed file types
    const acceptedTypes = ['jpg', 'gif', 'png', 'jpeg']

    // throw error
    const throwError = () => {
        alert(
            'Nahrání neproběhlo úspěšně. Zkontrolujte prosím, zda nahráváte povolený formát souboru (PNG, JPG, JPEG a GIF) a nepřekročili jste max.povolený limit 3 souborů.'
        )
    }

    // compress image
    const compressImage = async (file: File) => {
        const options = {
            maxSizeMB: 5,
        }
        try {
            // call browser-image-compression and get compressed blob
            return await imageCompression(file, options)
        } catch (error) {
            console.error('Error compressing image:', error)
            return false
        }
    }

    // listen uploaders updates
    const onChange = async (imageList: ImageListType) => {
        setLocaleStates((prevState: any) => {
            return { ...prevState, loading: true }
        })
        try {
            // get the new images that are not in the current state
            const newImages = imageList.filter(
                (incomingImage) =>
                    !images.some(
                        (currentImage) =>
                            currentImage.data_url === incomingImage.data_url
                    )
            )

            // compress new images
            const compressedNewImages =
                // wrap map by Promise.all to wait for all promises getting from the inner async fn
                // promise can return void bcs of problem during image compression because of that the array is filtered
                (
                    await Promise.all(
                        newImages.map(async (image) => {
                            if (image.file) {
                                // get image as a compressed blob
                                const compressedBlob = await compressImage(
                                    image.file
                                )
                                // throw error if the blob doesn't exist
                                if (!compressedBlob) {
                                    return throwError()
                                }
                                // transform blob into the more common File format
                                const compressedFile = new File(
                                    [compressedBlob],
                                    image.file.name,
                                    {
                                        type: compressedBlob.type,
                                        lastModified: Date.now(),
                                    }
                                )
                                // create a temporary URL for the compressedFile
                                const compressedFileURL =
                                    URL.createObjectURL(compressedFile)

                                return {
                                    data_url: compressedFileURL,
                                    file: compressedFile,
                                }
                            }
                            return image
                        })
                    )
                ).filter((image): image is ImageType => image !== undefined)

            // join origin images with new images
            const updatedImageList = [
                ...images.filter((image) =>
                    imageList.some(
                        (incomingImage) =>
                            image.data_url === incomingImage.data_url
                    )
                ),
                ...compressedNewImages,
            ]

            // update images
            setImages(updatedImageList)
        } catch (e) {
            console.log(e)
            throwError()
        } finally {
            setLocaleStates((prevState: any) => {
                return { ...prevState, loading: false }
            })
        }
    }

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNum}
            dataURLKey="data_url"
            acceptType={acceptedTypes}
            onError={(errors) => {
                console.log(errors)
                alert(
                    'Nahrání neproběhlo úspěšně. Zkontrolujte prosím, zda nahráváte povolený formát souboru (PNG, JPG, JPEG a GIF) a nepřekročili jste max.povolený limit 3 souborů.'
                )
            }}
        >
            {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                <>
                    {images.length === 0 && (
                        <button
                            className={styles.mainUploader}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <img
                                className={styles.mainUploaderThumb}
                                src="/img/layout/uploader-thumb.svg"
                                alt=""
                            />
                            <span className={styles.mainUploaderCTA}>
                                Nahrát fotografii (max 3)
                            </span>
                            <span className={styles.mainUploaderText}>
                                PNG, JPG, JPEG a GIF (max. 5 MB)
                            </span>
                        </button>
                    )}
                    <div className={styles.body}>
                        {images.length > 0 && images.length < 3 && (
                            <button
                                className={styles.secUploader}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                <img
                                    className={styles.secUploaderPlus}
                                    src="/img/icon/plus.svg"
                                    alt=""
                                />
                            </button>
                        )}
                        {imageList.map((image, index) => (
                            <div
                                className={styles.imgWrapper}
                                key={`custom-img-uploading-${index}`}
                            >
                                <img
                                    src={image['data_url']}
                                    alt=""
                                    className={styles.img}
                                />
                                <img
                                    onClick={() => onImageRemove(index)}
                                    className={styles.imgRemover}
                                    src="/img/layout/uploader-remover.svg"
                                    alt=""
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </ImageUploading>
    )
}
