import React, { useEffect, useRef, useState } from 'react'
import {
    MarkerClusterer,
    SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer'
import { StationProps } from '../../../type/StationProps'
import { useDeepCompareEffectForMaps } from '../../../utils/useDeepCompareEffectForMaps'
import { getCurrentPosition } from '../../../utils/geolocation'
import { handleGeocoder } from '../../../utils/handleGeocoder'
import { createMarker } from '../../../utils/createMarker'
import styles from './Map.module.scss'
import { renderer } from '../../../utils/clustererRenderer'
import { handleMarkers } from '../../../utils/handleMarkers'
import { PositionType } from '../../../type/PositionType'
import { StationObjProps } from '../../page/Map'
import { MapType } from '../../../type/MapType'

// lat and lng for default position
const defaultLat = Number(window.env.REACT_APP_DEFAULT_CENTER_LAT || 0)
const defaultLng = Number(window.env.REACT_APP_DEFAULT_CENTER_LNG || 0)
// default golemio radius
const defaultRadius = Number(window.env.REACT_APP_GOLEMIO_RANGE || 1000)
const wasteCollectionRange = Number(
    window.env.REACT_APP_BULKY_WASTE_RANGE || 22000
)

interface MapProps extends google.maps.MapOptions {
    children?: React.ReactNode
    position: undefined | google.maps.LatLngLiteral
    stations: StationProps[]
    setGeolocation: React.Dispatch<
        React.SetStateAction<undefined | google.maps.LatLngLiteral>
    >
    setPosition: (position: PositionType) => void
    activeStation: StationProps | undefined
    setActiveStation: (station: StationObjProps['activeStation']) => void
    map: google.maps.Map | undefined
    mapType: MapType
    setMap: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>
}

export const Map = ({
    setGeolocation,
    setPosition,
    children,
    position,
    stations,
    activeStation,
    setActiveStation,
    map,
    setMap,
    mapType,
    ...options
}: MapProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [circle, setCircle] = useState<google.maps.Circle>()
    const [clusterer, setClusterer] = useState<MarkerClusterer>()
    const radius =
        mapType === 'waste-collection' ? wasteCollectionRange : defaultRadius

    // init map
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options))
        }
    }, [ref, map])

    // set options
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options)
        }
    }, [map, options])

    // hook on init
    useEffect(() => {
        // set the position state (fill it by geolocation's coordinates or by default values from .env)
        getCurrentPosition({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                // check with geocoder that user is in Praha (geolocation is enabled only in Praha)
                handleGeocoder(
                    lat,
                    lng,
                    defaultLat,
                    defaultLng,
                    setPosition,
                    setGeolocation
                )
            },
            onError: (e) => {
                console.log(`Error ${e.code}: ${e.message}`)
                setPosition({ lat: defaultLat, lng: defaultLng })
            },
        })
    }, [])

    // hook for updating displayed stations
    useEffect(() => {
        if (map) {
            console.log('update clusters')

            // from current stations create an array of markers
            let markers = stations.map((station) => {
                return createMarker(station, map, mapType)
            })

            // extend current markers to handle an active station
            markers = handleMarkers(
                markers,
                map,
                activeStation,
                setActiveStation,
                mapType
            )

            // cluster is defined at the beginning
            if (!clusterer) {
                const algorithm = new SuperClusterAlgorithm({
                    maxZoom: mapType === 'bulky-waste' ? 11 : 19, // The maximum zoom level at which clustering is enabled or * `null` if clustering is to be enabled at all zoom levels. Default is null. But for our purpose has to be defined.
                    radius: 200,
                })
                setClusterer(
                    new MarkerClusterer({ markers, map, renderer, algorithm })
                )
            } else {
                // firstly old markers have to be cleared
                clusterer.clearMarkers()
                // and then new ones can be set
                clusterer.addMarkers(markers)
            }
        }
    }, [stations, map, activeStation])

    // hook for setting a circle representing a searched area
    useEffect(() => {
        if (position && map) {
            console.log('update circle')
            circle?.setMap(null)

            const localCircle = new google.maps.Circle({
                strokeColor: '#ffffff',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.35,
                map,
                center: position,
                radius,
            })

            map.setCenter(position)
            localCircle.setMap(map)
            setCircle(localCircle)
        }
    }, [map, position])

    return (
        <>
            <div className={styles.wrapper} ref={ref} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map })
                }
            })}
        </>
    )
}
