import React from 'react'
import clsx from 'clsx'
import styles from './MapZoom.module.scss'

interface MapZoomProps {
    map: google.maps.Map | undefined
    className?: string
}

export const MapZoom = ({ className, map }: MapZoomProps) => {
    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                onClick={() => {
                    if (map) {
                        const current = map.getZoom()
                        if (current) map.setZoom(current + 1)
                    }
                }}
                className={clsx(styles.icon, styles.plus)}
            >
                <img src="/img/icon/plus.svg" alt="" />
            </div>
            <hr className={styles.divider} />
            <div
                onClick={() => {
                    if (map) {
                        const current = map.getZoom()
                        if (current)
                            map.setZoom(current > 0 ? current - 1 : current)
                    }
                }}
                className={clsx(styles.icon, styles.minus)}
            >
                <img src="/img/icon/minus.svg" alt="" />
            </div>
        </div>
    )
}
